import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  Typography,
} from "@mui/material";
import type { PimoReactComponent } from "@pimo/pimo-app-builder";

type LockedFlowProps = Record<string, never>;
type LockedFlowEvent = "close";

export const LockedFlowDialog: PimoReactComponent<
  LockedFlowProps,
  LockedFlowEvent
> = ({ fireEvent }) => {
  return (
    <Dialog
      open
      PaperProps={{ sx: { borderRadius: 5 } }}
      onClose={() => fireEvent?.("close")}
    >
      <DialogTitle>Before proceeding</DialogTitle>
      <Divider />
      <Box
        sx={{
          m: 3,
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Typography>
          Please finalize the general parameters before editing scenarios.
        </Typography>
      </Box>
      <DialogActions
        sx={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "flex-end",
          mt: 2,
          ml: 3,
          mr: 3,
          mb: 2,
          gap: 2,
        }}
      >
        <Button
          type="submit"
          variant="contained"
          onClick={() => fireEvent?.("close")}
        >
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
