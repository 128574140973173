/* eslint-disable @typescript-eslint/no-misused-promises */
import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  Dialog,
  DialogActions,
  DialogTitle,
  Divider,
  FormControl,
  FormHelperText,
  InputLabel,
  Link,
  MenuItem,
  Popover,
  Select,
  TextField,
  Typography,
  useTheme,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { FC, useEffect, useState } from "react";
import React from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import {
  EntityValidationResult,
  FE_OEStatus,
  SUBVERSIONS_FOR_TECHNICAL_VULNERABILITY_EXPLOITATION,
} from "tracy-types";

import { getSubversionTitle } from "../../../../helpers/get-subversion-title";
import { getErrorMessage } from "../../../../helpers/validation/get-error-message";
import { hasError } from "../../../../helpers/validation/has-error";
import { ConfirmationDialogBox } from "../confirmation-dialog-box";

export interface EditSettingsDialogProps {
  oeName?: string;
  year?: number;
  oeStatus?: FE_OEStatus;
  formValidationResult?: EntityValidationResult;
}

export const EditSettingsDialog: PimoReactComponent<
  EditSettingsDialogProps,
  "submit" | "cancel",
  Partial<FE_OEStatus>
> = ({ oeStatus, oeName, year, fireEvent, formValidationResult }) => {
  const { register, handleSubmit, reset, setValue } = useForm();

  const theme = useTheme();

  const [isComfirmationDialogOpen, setIsComfirmationDialogOpen] =
    useState(false);

  const [isButtonDisabled, setIsButtonDisabled] = useState(
    !(
      oeStatus?.ratingSchema ||
      oeStatus?.operatingProfit ||
      !oeStatus?.subversionForTechnicalVulnerabilityExploitation ||
      oeStatus.contact
    )
  );

  const [currentPopoverContent, setCurrentPopoverContent] = useState<
    "scheme" | "profit" | "scenario" | undefined
  >();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(
    null
  );

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);

  const onSubmit: SubmitHandler<Partial<FE_OEStatus>> = (data) => {
    fireEvent?.("submit", data);
  };

  const onCancel = () => {
    setIsComfirmationDialogOpen(true);
  };

  useEffect(() => {
    reset(oeStatus);
  }, [oeStatus, reset]);

  useEffect(() => {
    setValue("ratingSchema", "ASTRA");
  }, [setValue]);

  return (
    <>
      <ConfirmationDialogBox
        open={isComfirmationDialogOpen}
        onContinue={() => {
          setIsComfirmationDialogOpen(false);
        }}
        onClose={() => {
          setIsComfirmationDialogOpen(false);
          fireEvent?.("cancel");
        }}
      />
      <Dialog
        open
        maxWidth={"lg"}
        PaperProps={{ sx: { borderRadius: 5 } }}
        onClose={() => setIsComfirmationDialogOpen(true)}
      >
        <form onSubmit={handleSubmit(onSubmit as SubmitHandler<FieldValues>)}>
          <input type="hidden" {...register("id")} />
          <DialogTitle sx={{ fontWeight: 500, width: 1200 }}>
            Edit Settings for {oeName} ({year})
          </DialogTitle>
          <Divider />
          <Box
            sx={{
              background: "#F5F5F5",
              borderRadius: 5,
              m: 3,
              p: 2,
              display: "flex",
              flexDirection: "row",
              justifyContent: "space-between",
            }}
          >
            <Typography sx={{ fontStyle: "italic" }}>
              Please set your parameters for your TRACY Reporting.
            </Typography>
          </Box>
          <Divider />
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: 4,
              ml: 3,
              mr: 5,
              gap: 2,
            }}
          >
            {/* <Box
              sx={{
                flex: "0 0 50%",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <FormControl
                fullWidth
                error={hasError("ratingSchema", formValidationResult)}
              >
                <InputLabel>Select Rating Scheme</InputLabel>
                <Select
                  inputProps={register("ratingSchema")}
                  defaultValue={oeStatus?.ratingSchema ?? ""}
                  variant="outlined"
                  label="Select Rating Scheme"
                  error={hasError("ratingSchema", formValidationResult)}
                  onChange={() => setIsButtonDisabled(false)}
                >
                  {RATING_SCHEMAS.map((schema) => (
                    <MenuItem value={schema} key={schema}>
                      {schema}
                    </MenuItem>
                  ))}
                </Select>
                {hasError("ratingSchema", formValidationResult) && (
                  <FormHelperText sx={{ color: theme.palette.error.main }}>
                    {getErrorMessage("ratingSchema", formValidationResult)}
                  </FormHelperText>
                )}
              </FormControl>
              <Button
                id="scheme"
                sx={{ p: 0, minWidth: 0 }}
                onClick={(event) => {
                  handleClick(event);
                  setCurrentPopoverContent("scheme");
                }}
              >
                <InfoOutlined sx={{ color: "#616161" }} />
              </Button>
            </Box> */}
            <Box
              sx={{
                flex: "0 0 50%",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <FormControl fullWidth>
                <TextField
                  label="Operating Profit"
                  type="number"
                  inputProps={register("operatingProfit", {
                    valueAsNumber: true,
                  })}
                  onChange={() => setIsButtonDisabled(false)}
                  error={hasError("operatingProfit", formValidationResult)}
                  helperText={getErrorMessage(
                    "operatingProfit",
                    formValidationResult
                  )}
                />
              </FormControl>
              <Button
                id="scheme"
                sx={{ p: 0, minWidth: 0 }}
                onClick={(event) => {
                  handleClick(event);
                  setCurrentPopoverContent("profit");
                }}
              >
                <InfoOutlined sx={{ color: "#616161" }} />
              </Button>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              mt: 4,
              ml: 3,
              mr: 5,
              gap: 2,
            }}
          >
            <Box
              sx={{
                flex: "0 0 50%",
                display: "flex",
                alignItems: "center",
                gap: 1,
              }}
            >
              <FormControl
                fullWidth
                error={hasError(
                  "subversionForTechnicalVulnerabilityExploitation",
                  formValidationResult
                )}
              >
                <InputLabel>
                  Subversion for Scenario "Technical Vulnerability Exploitation"
                </InputLabel>
                <Select
                  inputProps={register(
                    "subversionForTechnicalVulnerabilityExploitation"
                  )}
                  defaultValue={
                    oeStatus?.subversionForTechnicalVulnerabilityExploitation
                  }
                  onChange={() => setIsButtonDisabled(false)}
                  error={hasError(
                    "subversionForTechnicalVulnerabilityExploitation",
                    formValidationResult
                  )}
                  variant="outlined"
                  label={`Subversion for Scenario "Technical Vulnerability Exploitation"`}
                >
                  {SUBVERSIONS_FOR_TECHNICAL_VULNERABILITY_EXPLOITATION.map(
                    (subversion) => (
                      <MenuItem value={subversion} key={subversion}>
                        {getSubversionTitle(subversion)}
                      </MenuItem>
                    )
                  )}
                </Select>
                {hasError(
                  "subversionForTechnicalVulnerabilityExploitation",
                  formValidationResult
                ) && (
                  <FormHelperText sx={{ color: theme.palette.error.main }}>
                    {getErrorMessage(
                      "subversionForTechnicalVulnerabilityExploitation",
                      formValidationResult
                    )}
                  </FormHelperText>
                )}
              </FormControl>
              <Button
                id="scheme"
                sx={{ p: 0, minWidth: 0 }}
                onClick={(event) => {
                  handleClick(event);
                  setCurrentPopoverContent("scenario");
                }}
              >
                <InfoOutlined sx={{ color: "#616161" }} />
              </Button>
            </Box>
            <Box
              sx={{
                flex: "0 0 50%",
                display: "flex",
              }}
            >
              <FormControl fullWidth>
                <TextField
                  label="Contact Person"
                  type="text"
                  inputProps={register("contact")}
                  error={hasError("contact", formValidationResult)}
                  onChange={() => setIsButtonDisabled(false)}
                  helperText={getErrorMessage("contact", formValidationResult)}
                />
              </FormControl>
            </Box>
          </Box>
          <Divider
            sx={{
              bgcolor: theme.palette.secondary.main,
              height: "1px",
              borderWidth: 0,
              mt: 10,
            }}
          />
          <DialogActions
            sx={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
              mt: 2,
              ml: 3,
              mr: 3,
              mb: 2,
              gap: 2,
            }}
          >
            <Button type="button" variant="text" onClick={() => onCancel()}>
              Cancel
            </Button>
            <Button
              disabled={isButtonDisabled}
              type="submit"
              variant="contained"
            >
              Save
            </Button>
          </DialogActions>
        </form>
      </Dialog>
      <DetailsDialog
        open={open}
        content={currentPopoverContent}
        anchorEl={anchorEl}
        handleClose={handleClose}
      />
    </>
  );
};

const DetailsDialog: FC<{
  open: boolean;
  content?: "scheme" | "profit" | "scenario";
  handleClose: () => void;
  anchorEl: HTMLButtonElement | null;
}> = ({ open, handleClose, content = "scheme", anchorEl }) => {
  return (
    <Popover
      sx={{ ".MuiPopover-paper": { borderRadius: "12px" } }}
      open={open}
      onClose={handleClose}
      anchorEl={anchorEl}
    >
      <Card
        sx={{
          display: "flex",
          flexDirection: "column",
          boxShadow: "none",
          height: "100%",
          minWidth: 500,
          maxWidth: 600,

          p: 2,
          "@media print": {
            border: "1px solid rgba(0, 0, 0, 0.12)",
          },
        }}
      >
        {content === "profit" && (
          <Typography>
            Please enter here the operating profit from ORGS, which is the basis
            for all the financial impact calculation for the scenarios.
            <br />
            <br />
            If your OE has little to no/negative operating profit, your OE is
            still required to perform a TRA. In such instances consideration for
            inclusion as a top risk should follow an alternative basis, for
            example an economic impact exceeding 10% of revenues or total cost;
            or for banks a threshold of 40% of excess capital.
          </Typography>
        )}
        {content === "scenario" && (
          <>
            <Typography>
              Note that for the scenario "Technical Vulnerability Exploitation"
              there are two subversions, which differ in terms of motivation,
              further action / attack and resulting impact on your OE. In the
              "Data Stealing Variation", the attackers aim to leak and
              manipulate confidential data to cause damage; while in the
              "Financial Motive variation", they aim to manipulate payment data
              and make unauthorized payments (see also the scenario
              documentation for more details:{" "}
              <Link
                href="https://allianzms.sharepoint.com/sites/DE1890-connect-azse-group-technology/SitePages/TRACY.aspx"
                rel="noreferrer"
                target="_blank"
              >
                https://allianzms.sharepoint.com/sites/DE1890-connect-azse-group-technology/SitePages/TRACY.aspx"
              </Link>{" "}
              . Each OE can choose one of the two subversions, which is
              considered to be more relevant.{" "}
            </Typography>
          </>
        )}
        {content === "scheme" && (
          <>
            <Typography>
              You can choose between the ASTRA and AFIRM rating scheme for the
              determination of the Financial Impact ratings based on the
              percentage of the Operating Profit. Please use the rating scheme
              that is considered to be more appriopriate for your OE, e.g.
              according to the business model and risk profile. The rating
              schemes are as follows:
            </Typography>
            <Box component={"img"} src="ratings-table.png" />
          </>
        )}
      </Card>
    </Popover>
  );
};
