/* eslint-disable @typescript-eslint/no-misused-promises */

import { Box } from "@mui/material";
import { FormProvider } from "react-hook-form";

import { useFadeIn } from "../../hooks/use-fade-in";
import { useScenariosForm } from "../../hooks/use-scenarios-form";
import { ScenariosErrorsDialog } from "./scenarios-errors-dialog";
import { ScenariosFormHeader } from "./scenarios-form-header";
import { ScenariosFormComponent } from "./types";

export const ScenariosForm: ScenariosFormComponent = ({
  children,
  showValidationErrorsDialog,
  ...props
}) => {
  const { ref, styles } = useFadeIn({ duration: 200 });
  const { formMethods, handleSubmit } = useScenariosForm({
    fireEvent: props.fireEvent,
    formValues: props.formValues,
  });

  return (
    <>
      <Box
        ref={ref}
        sx={{
          ...styles,
          display: "flex",
          flexDirection: "column",
          flexShrink: 0,
          height: "100%",
        }}
      >
        <ScenariosFormHeader {...props} />
        <FormProvider {...formMethods}>
          <form
            onSubmit={handleSubmit}
            style={{ display: "flex", flexDirection: "column", flexGrow: 1 }}
          >
            {children}
          </form>
        </FormProvider>
      </Box>
      <ScenariosErrorsDialog
        showValidationErrorsDialog={!!showValidationErrorsDialog}
        onContinue={() => props.fireEvent?.("form:ignore-errors")}
        onResolve={() => props.fireEvent?.("form:show-errors")}
      />
    </>
  );
};
