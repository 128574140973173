import {
  App,
  composeInitialState,
  LoginPlugin,
  LoginPluginPartialAppState,
  UniversalAppState,
} from "@pimo/pimo-app-builder";
import {
  DefaultOverlayPartialAppState,
  initialDefaultOverlayState,
} from "@pimo/pimo-components";
import type {
  FE_OrganizationalEntity,
  FilterData,
  GroupDashboardPage,
  OEClusterResponse,
  OEOverviewPage,
  OEReportPage,
  PermissionResponse,
  Profile,
} from "tracy-types";

import { APP_KEY } from "../app-key";
import { APP_ROUTES } from "./constants";
import { STRAPI_URL } from "./env";
import { fetchOEClusters } from "./helpers/fetch/fetch-clusters";
import { fetchOEs } from "./helpers/fetch/fetch-oes";
import { fetchPermissions } from "./helpers/fetch/fetch-permissions";
import { HomeRouteHandlerPlugin } from "./plugins/home-route-handler-plugin";
import { GroupDashboardPlugin } from "./plugins/pages/group-dashboard-plugin";
import { OEOverviewPlugin } from "./plugins/pages/oe-overview-plugin";
import { OEReportPlugin } from "./plugins/pages/oe-report-plugin";
import {
  ScenariosPlugin,
  ScenariosPluginState,
} from "./plugins/pages/scenarios-plugin";
import { TracyOverlayPlugin } from "./plugins/tracy-overlay-plugin";
import { theme } from "./theme";

export type TracyAppState = DefaultOverlayPartialAppState &
  LoginPluginPartialAppState<Profile> &
  UniversalAppState &
  ScenariosPluginState & {
    year: number;
    oes: FE_OrganizationalEntity[];
    currentOEReportPage?: OEReportPage;
    currentOEReportPageYear?: number;
    oeOverviewPage?: OEOverviewPage;
    dashboardPage?: GroupDashboardPage;
    filterState?: FilterData;
    filterValues?: { oeProjects?: string[]; contacts?: string[] };
    permissions: PermissionResponse;
    oeClusters: OEClusterResponse[];
  };

// Plugins
const loginPlugin = new LoginPlugin<Profile, TracyAppState>(
  APP_ROUTES.groupDashboard,
  STRAPI_URL,
  "TRACY"
);

// App
const app = App.create<TracyAppState>(
  composeInitialState<TracyAppState>({
    ...initialDefaultOverlayState,
    ...loginPlugin.getInitialState(),
    formValues: {},
    isLoading: false,
    isSidebarOpen: true,
    oes: [],
    oeClusters: [],
    permissions: [],
    year: +new Date().getFullYear(),
  }),
  APP_KEY
);

app.setTheme(theme);

const overlayPlugin = new TracyOverlayPlugin();
const homeRouteHandlerPlugin = new HomeRouteHandlerPlugin();
const groupDashboardPlugin = new GroupDashboardPlugin();
const oeOverviewPlugin = new OEOverviewPlugin();
const oeReportPlugin = new OEReportPlugin();

app.registerPlugin(overlayPlugin);
app.registerPlugin(loginPlugin);
app.registerPlugin(homeRouteHandlerPlugin);
app.registerPlugin(groupDashboardPlugin);
app.registerPlugin(oeOverviewPlugin);
app.registerPlugin(oeReportPlugin);

/**
 * we intenionally initialize and register the `ScenariosPlugin` after all of the other plugins.
 * this way we already have access to the `OEReportPlugin`'s route, which we need to register the
 * `ScenariosPlugin`'s route.
 */
// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-ignore - this is fine
const scenariosPlugin = new ScenariosPlugin(oeReportPlugin.route!);

app.registerPlugin(scenariosPlugin);

overlayPlugin.on("logout", () => {
  loginPlugin.logout();
});

loginPlugin.on("login-status-changed", async (event) => {
  if (!event.payload?.isLoggedIn) {
    return;
  }

  const oes = await fetchOEs();
  const permissions = await fetchPermissions();
  const oeClusters = await fetchOEClusters();

  app.setAppState({
    ...app.getAppState(),
    oes,
    permissions,
    oeClusters,
  });
});

const RenderedApp = app.render();

export default RenderedApp;
