import { Box, Divider, styled, Typography, useTheme } from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { useCallback } from "react";
import { CommonRatingValue, FINANCIAL_RATING } from "tracy-types";

import { TracyTooltip } from "../tracy-tooltip";

export interface RiskMatrixProps {
  title: string;
  /** the entries that will populate the matrix */
  entries: {
    /** text to be displayed in the badge */
    name: string;
    /** Y coordinate */
    yAxisField: CommonRatingValue;
    /** X coordinate */
    xAxisField: CommonRatingValue;
    /** (optional) need for redirection */
    findingId?: string;
    /** should the entry be seen on the tooltip */
    isEntryVisibleOnTooltip?: boolean;
    /** (optional) on click */
    onClick?: () => void;
  }[][];
}

const MapSquare = styled(Box)(() => ({
  display: "flex",
  flexDirection: "row",
  flexWrap: "wrap",
  zIndex: 0,
  maxWidth: "100%",
  minWidth: "100%",
  maxHeight: 100,
  justifyContent: "center",
  alignItems: "center",
}));

const Container = styled(Box)(({ theme }) => ({
  backgroundColor: "white",
  marginBottom: theme.spacing(1),
  borderRadius: "12px",
  width: "100%",
}));

const Title = styled(Box)(() => ({
  fontWeight: 500,
  fontSize: "1.25rem",
}));

const GraphContainer = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "0.2fr 7fr",
  width: "95%",
  minHeight: 500,
  margin: 10,
}));

const YLabel = styled(Box)(() => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  transform: "rotate(-90deg)",
  fontSize: "20px",
  width: 60,
  fontWeight: 500,
  whiteSpace: "nowrap",
}));

const XLabel = styled(Box)(() => ({
  width: "100%",
  display: "flex",
  flexDirection: "row",
  verticalAlign: "middle",
  justifyContent: "center",
  alignItems: "center",
  fontSize: "20px",
  fontWeight: 500,
}));

const XTicks = styled(Box)(() => ({
  verticalAlign: "middle",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
}));
const YTicks = styled(Box)(() => ({
  verticalAlign: "middle",
  display: "flex",
  alignItems: "center",
  justifyContent: "start",
}));
const HeatMap = styled(Box)(() => ({
  display: "grid",
  gridTemplateColumns: "repeat(5, 1fr)",
  gridTemplateRows: "repeat(5, 1fr)",
  background:
    "linear-gradient(36deg, rgba(0,242,30,1) 14%, rgba(239,223,14,1) 43%, rgba(255,237,0,1) 59%, rgba(255,0,0,1) 100%)",
}));

export const RiskMatrix: PimoReactComponent<RiskMatrixProps> = ({
  entries,
  title,
}) => {
  const theme = useTheme();

  /** function to generate the matrix squares, and respecitvely the badges that will populate them */
  const generateBoxes = useCallback(
    (
      items: {
        name: string;
        xAxisField: number;
        isEntryVisibleOnTooltip?: boolean;
        yAxisField: number;
        onClick?: () => void;
      }[][]
    ) => {
      const listItems = [];
      for (let i = 0; i < 5; i++) {
        for (let j = 0; j < 5; j++) {
          listItems.push(
            <MapSquare key={`cell${i} - ${j}`}>
              {items
                .filter(
                  (item) => item[0].yAxisField === j && item[0].xAxisField === i
                )
                .map((item, index) => (
                  <Box
                    key={`badge-container${index}${Math.random()}`}
                    id={`badge-container${item[0].name}`}
                    sx={{
                      display: "flex",
                      flexDirection: "row",
                    }}
                  >
                    <TracyTooltip
                      title={
                        item.filter((item) => item.isEntryVisibleOnTooltip)
                          .length > 0
                          ? item
                              .filter((item) => item.isEntryVisibleOnTooltip)
                              .map((i, index) => `${index + 1}. ${i.name} \n`)
                          : undefined
                      }
                    >
                      <Box
                        id={`point${item[0].name}`}
                        sx={{
                          backgroundColor: "#2f8ee7",
                          margin: theme.spacing(0.5),
                          border: "solid",
                          width: `${35 + item.length * 2}px`,
                          height: `${35 + item.length * 2}px`,
                          maxHeight: "80px",
                          maxWidth: "80px",
                          fontSize: "1.5rem",
                          lineHeight: `${35 + item.length * 1.5}px`,
                          borderRadius: "100%",
                          textAlign: "center",
                          verticalAlign: "middle",
                          color: "white",
                        }}
                        key={`${item[0].yAxisField - item[0].xAxisField}`}
                      >
                        {item.length}
                      </Box>
                    </TracyTooltip>
                  </Box>
                ))}
            </MapSquare>
          );
        }
      }
      return listItems;
    },
    [theme]
  );

  return (
    <Container data-testid="heat-map-container">
      <Box
        sx={{
          display: "flex",
          gap: 2,
          justifyContent: "space-between",
          padding: 2,
        }}
      >
        <Title>{title}</Title>
        <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
          <Typography fontWeight={500} fontSize={18}>
            Legend:{" "}
          </Typography>
          <Box
            sx={{
              backgroundColor: "#2f8ee7",
              margin: theme.spacing(0.2),
              border: "solid",
              width: `30px`,
              height: `30px`,
              maxHeight: "80px",
              maxWidth: "80px",
              fontSize: "1.5rem",
              lineHeight: `25px`,
              borderRadius: "100%",
              textAlign: "center",
              verticalAlign: "middle",
              color: "white",
            }}
          >
            #
          </Box>
          <Typography fontWeight={400} fontSize={18} ml={-2}>
            #OEs
          </Typography>
        </Box>
      </Box>
      <Divider
        sx={{
          bgcolor: theme.palette.secondary.main,
          height: "1px",
          borderWidth: 0,
        }}
      />
      <GraphContainer sx={{ py: 2 }}>
        <YLabel>Non Financial Impact</YLabel>
        <Box
          sx={{
            display: "grid",
            gridTemplateRows: "7fr 0.5fr",
          }}
        >
          <Box
            sx={{
              display: "grid",
              gridTemplateColumns: "0.25fr 6fr",
            }}
          >
            <Box>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateRows: "repeat(6, 1fr)",
                  height: "100%",
                }}
              >
                {[...FINANCIAL_RATING]
                  .sort((a, b) => b - a)
                  .map((severity) => (
                    <YTicks key={"ytick" + severity.toString()}>
                      {severity}
                    </YTicks>
                  ))}
                <Box sx={{ verticalAlign: "middle" }} />
              </Box>
            </Box>
            <Box
              sx={{
                display: "grid",
                gridTemplateRows: "6fr 1fr",
              }}
            >
              <HeatMap id="HeatMap">
                {generateBoxes(
                  entries.map((x) =>
                    x.map((y) => ({
                      ...y,
                      xAxisField: 5 - y.yAxisField,
                      yAxisField: y.xAxisField - 1,
                    }))
                  )
                )}
              </HeatMap>
              <Box
                sx={{
                  display: "grid",
                  gridTemplateColumns: "repeat(5, 1fr)",
                }}
              >
                {FINANCIAL_RATING.map((probability) => (
                  <XTicks key={"xtick" + probability.toString()}>
                    {probability}
                  </XTicks>
                ))}
              </Box>
            </Box>
          </Box>
          <XLabel>Financial Impact</XLabel>
        </Box>
      </GraphContainer>
    </Container>
  );
};
