import { InfoOutlined } from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  Divider,
  IconButton,
  Modal,
  Typography,
  useTheme,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { type ReactNode, useState } from "react";
import { ScenarioStepStatus } from "tracy-types";

import { ScenariosStatusIndicator } from "../scenarios/scenarios-status-indicator";
import { TracyTooltip } from "../tracy-tooltip";

export type SectionHeaderProps<EventName extends string> = {
  title: ReactNode;
  buttons?: {
    title: string;
    icon?: ReactNode;
    event: EventName;
    isLocked?: boolean;
    showTooltip?: boolean;
  }[];
  chip?: {
    title: string;
    colorScheme: { chipColor: string; textColor: string };
  };
  infoIcon?: { modalHeadline: string; modalText: string };
  updateStatus?: ScenarioStepStatus;
  fireEvent?: (eventName: EventName) => void;
  componentId?: string;
};

export function OEReportSectionHeader<EventName extends string>({
  title,
  chip,
  infoIcon,
  updateStatus,
  buttons,
  fireEvent,
  componentId,
}: SectionHeaderProps<EventName>): ReturnType<
  PimoReactComponent<SectionHeaderProps<EventName>, EventName>
> {
  const theme = useTheme();
  const [isInfoIconModalOpen, setIsInfoIconModalOpen] = useState(false);

  return (
    <Box
      data-testid="SectionHeader"
      id={componentId}
      sx={{
        alignItems: "center",
        backgroundColor: theme.palette.primary.main,
        borderRadius: 6,
        color: theme.palette.common.white,
        display: "flex",
        height: 40,
        justifyContent: "flex-start",
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(2),
        scrollMarginTop: theme.spacing(8),
        "@media print": {
          pageBreakBefore: "always",
        },
      }}
    >
      <Box sx={{ display: "flex", flexDirection: "row", flex: "1 1 1" }}>
        <Typography
          sx={{
            fontWeight: theme.typography.fontWeightBold,
          }}
        >
          {title}
        </Typography>
      </Box>
      {chip && (
        <Chip
          label={chip.title}
          sx={{
            background: chip.colorScheme.chipColor,
            height: 28,
            ml: 2,
            pl: 1,
            pr: 1,
            color: chip.colorScheme.textColor,
          }}
        />
      )}
      {/* box to push edit buttons all the way to the end */}
      <Box sx={{ flexGrow: 10 }} />
      <Box sx={{ display: "flex", alignItems: "center" }}>
        {infoIcon && (
          <>
            <IconButton
              sx={{ mr: 2, color: "#fff" }}
              onClick={() => setIsInfoIconModalOpen(true)}
            >
              <InfoOutlined />
            </IconButton>
            <Modal
              open={isInfoIconModalOpen}
              onClose={() => setIsInfoIconModalOpen(false)}
            >
              <Box
                sx={{
                  background: theme.palette.background.paper,
                  position: "absolute",
                  top: "40%",
                  left: "50%",
                  width: "80%",
                  minHeight: "40%",
                  transform: "translate(-50%,-50%)",
                  p: 3,
                }}
              >
                <Typography component="h2" variant="h5">
                  {infoIcon.modalHeadline}
                </Typography>
                <Divider sx={{ mt: 2, mb: 3 }} />
                <Typography>{infoIcon.modalText}</Typography>
              </Box>
            </Modal>
          </>
        )}
        {updateStatus && (
          <Box
            sx={{
              mr: 2,
              display: "flex",
              flexDirection: "row",
              alignItems: "center",
            }}
          >
            <Typography sx={{ color: "#fff", mr: 1 }}>Update Status</Typography>
            <ScenariosStatusIndicator status={updateStatus} />
          </Box>
        )}
        {buttons?.map(
          ({ event, icon, title, isLocked, showTooltip = true }) => {
            return (
              <TracyTooltip
                key={title}
                title={
                  isLocked && showTooltip ? (
                    <p>
                      Edit functionality is locked, please contact{" "}
                      <a href="mailto:isrm@allianz.com">isrm@allianz.com</a> to
                      unlock it.
                    </p>
                  ) : undefined
                }
              >
                <span>
                  <Button
                    key={title}
                    onClick={() => fireEvent?.(event)}
                    variant="outlined"
                    startIcon={icon}
                    disabled={isLocked}
                    sx={{
                      height: 30,
                      backgroundColor: theme.palette.background.default,
                      color: theme.palette.primary.main,
                      ml: 1,
                      borderRadius: 2,
                      "&:hover": {
                        backgroundColor: theme.palette.secondary.main,
                        color: theme.palette.primary.main,
                      },
                    }}
                  >
                    {title} {isLocked && "🔒"}
                  </Button>
                </span>
              </TracyTooltip>
            );
          }
        )}
      </Box>
    </Box>
  );
}

export default OEReportSectionHeader;
