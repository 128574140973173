import { Box, Typography, useTheme } from "@mui/material";
import { type PimoReactComponent } from "@pimo/pimo-app-builder";

/* eslint-disable-next-line @typescript-eslint/ban-types */
export type ScenariosFilterProps = {};

export const ScenariosSidebarHeadline: PimoReactComponent<
  ScenariosFilterProps
> = () => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        alignItems: "center",
        backgroundColor: theme.palette.secondary.light,
        display: "flex",
        flex: 1,
        height: "100%",
        justifyContent: "center",
        p: 2,
        pb: 0,
      }}
    >
      <Typography sx={{ fontWeight: "bold" }}>Scenarios</Typography>
    </Box>
  );
};
