import { Box, Typography } from "@mui/material";
import { ApexOptions } from "apexcharts";
import React from "react";
import ReactApexChart from "react-apexcharts";

export type PercentageCompletedCellProps = {
  label: string;
  percentage: number;
  colors?: {
    completed: string;
    remaining: string;
  };
};
export const PercentageCompletedCell: React.FC<
  PercentageCompletedCellProps
> = ({
  label,
  percentage,
  colors = {
    remaining: "#F5F5F5",
    completed: "#94CADC",
  },
}) => {
  const chartOptions: ApexOptions = {
    labels: [],
    colors: [colors.completed, colors.remaining],
    legend: { show: false },
    dataLabels: { enabled: false },
    tooltip: { enabled: false },
    plotOptions: {
      pie: {
        donut: {
          size: "50%",
          labels: { show: false, value: { show: false } },
        },
      },
    },
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        gap: 2,
      }}
    >
      <Typography sx={{ whiteSpace: "nowrap" }}>{label}</Typography>
      <ReactApexChart
        type="donut"
        series={[percentage, 100 - percentage]}
        options={chartOptions}
        height={"90px"}
        width={"60px"}
      />
    </Box>
  );
};
