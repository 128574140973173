import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";

import type {
  ScenariosFormEvent,
  ScenariosFormEventPayload,
  ScenariosFormValues,
} from "../components/scenarios/types";

type UseScenariosFormProps = {
  fireEvent:
    | ((
        event: ScenariosFormEvent,
        payload?: ScenariosFormEventPayload | undefined
      ) => void)
    | undefined;
  formValues?: ScenariosFormValues;
  reset?: boolean;
};

/**
 * custom hook to which serves as a wrapper for `useForm`.
 * on mount, it registers the form and its methods with the `fireEvent` handler.
 * on unmount, it unregisters the form and its methods with the `fireEvent` handler.
 */
export function useScenariosForm({
  fireEvent,
  formValues,
  reset = true,
}: UseScenariosFormProps) {
  const onSubmit = (formValues: ScenariosFormValues) => {
    fireEvent?.("form:submit", { formValues });
  };

  const formMethods = useForm<ScenariosFormValues>({
    defaultValues: formValues,
  });
  const values = formMethods.watch();
  const [previousValues, setPreviousValues] =
    useState<ScenariosFormValues | null>(null);

  const { id: scenarioId, updatedAt: scenarioUpdatedAt } =
    formValues?.scenarioParameters ?? {};

  useEffect(
    () => {
      if (reset) {
        formMethods.reset(formValues);
      }
    },
    // we conciously only want to watch `formValues.scenarioParamters.id` and not the entire form values
    // to not do too many update runs
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [scenarioId, scenarioUpdatedAt, reset]
  );

  useEffect(() => {
    // debounce
    const handler = setTimeout(() => {
      if (
        previousValues &&
        JSON.stringify(values) !== JSON.stringify(previousValues)
      ) {
        fireEvent?.("form:touched");
        setPreviousValues(values);
      }
      fireEvent?.("form:values-changed", { formValues: values });
    }, 200);

    if (!previousValues) {
      setPreviousValues(values);
    }

    return () => clearTimeout(handler);
    /** this is fine, values is going to be the same reference */
    /* eslint-disable-next-line react-hooks/exhaustive-deps */
  }, [JSON.stringify(values)]);

  return {
    formMethods,
    handleSubmit: formMethods.handleSubmit(onSubmit),
  };
}
