import type { PimoReactComponent } from "@pimo/pimo-app-builder";
import type { PropsWithChildren } from "react";
import type {
  EntityValidationResult,
  FE_Scenario,
  OERatingResponse,
  OEReportPage,
  RiskType,
  ScenariosFormValues,
  ScenariosFormValuesRequest,
  StepCount,
} from "tracy-types";

import { ScenarioParametersEvents } from "./scenarios-steps/0-scenario-parameters";

export type { ScenariosFormValues } from "tracy-types";

export const SCENARIO_STEPS_CATEGORY_NAMES = [
  "Scenario Parameters",
  "Current Cyber Risk",
  "Mitigation Actions",
  "Residual Cyber Risks",
] as const;

export const SCENARIO_STEPS_BY_CATEGORY = {
  "Scenario Parameters": [0],
  "Current Cyber Risk": [1, 2, 3, 4, 5],
  "Mitigation Actions": [6],
  "Residual Cyber Risks": [7, 8, 9, 10],
} satisfies Record<ScenarioFlowCategory, StepCount[]>;

export type ScenarioFlowCategory =
  (typeof SCENARIO_STEPS_CATEGORY_NAMES)[number];

export type ScenarioFlowStep = {
  /** the step's component, typically containing a form element. */
  component: ScenariosFormComponent;
  /** the step's category. */
  category: ScenarioFlowCategory;
  /** the step's description. */
  shortDescription: string | undefined;
  longDescription: string | undefined;
  images: string[] | undefined;
  /** the step's name. */
  name: string;
  /** the step's number. */
  number: StepCount;
  /**
   * the step's slug, under which the step will be made accessible.
   * should start with a leading `/`.
   */
  slug: string;
  /** the step's title. */
  title: string;
  /**
   * the step's viewname, which will be used to render the step's content.
   * should be the same as the step's slug, but without the leading `/`.
   */
  viewname: string;

  step?: ScenariosFormValuesRequest["step"];

  riskType?: RiskType;
};

// eslint-disable-next-line @typescript-eslint/ban-types
export type ScenariosFormComponent<T = {}> = PimoReactComponent<
  PropsWithChildren<ScenariosFormProps & T>,
  ScenariosFormEvent,
  ScenariosFormEventPayload
>;

export type ScenariosFormProps = {
  oeRatings: OERatingResponse;
  oeReportPage: OEReportPage;
  scenario: FE_Scenario;
  step: ScenarioFlowStep;
  disabled?: boolean;
  formValues?: ScenariosFormValues;
  validationResult?: EntityValidationResult;
  showValidationErrorsDialog?: boolean;
};

export type ScenariosFormEvent =
  | "form:values-changed"
  | "form:submit"
  | "form:ignore-errors"
  | "form:show-errors"
  | "form:touched"
  | ScenarioParametersEvents;

export type ScenariosFormEventPayload = {
  formValues: ScenariosFormValues;
};
