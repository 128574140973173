import { App, DeriveTableProps, Plugin, Table } from "@pimo/pimo-app-builder";
import {
  DateCell,
  GridLayout,
  IndexCell,
  NumberCard,
  ProgressBarCell,
  TextCardCell,
} from "@pimo/pimo-components";
import { generatePath } from "react-router-dom";
import { FE_OEStatus, ScenarioStepStatus, UpdateStatus } from "tracy-types";

import { TracyAppState } from "../../app";
import { OEOverviewTitleCard } from "../../components/oe-overview-title-card";
import { CheckBoxCell } from "../../components/overview/checkbox-cell";
import { APP_ROUTES } from "../../constants";
import { fetchOEOverviewPage } from "../../helpers/fetch/fetch-oe-overview-page";

export class OEOverviewPlugin implements Plugin<TracyAppState> {
  onRegister(app: App<TracyAppState>): void {
    const view = app.createView({
      name: "Status Overview",
      layout: new GridLayout(),
    });

    const headline = view.addComponent({
      component: OEOverviewTitleCard,
      layoutProps: {
        xs: 12,
      },
    });
    headline.mapState(({ year, filterState, oes, oeClusters }) => {
      return {
        title: `Status Overview (${year})`,
        filterData: filterState ?? {
          searchQueryFilter: "",
          topRiskFilter: [],
          nameFilter: [],
          contactFilter: [],
          reportStatusFilter: [],
          clusterFilter: [],
        },
        filterValues: {
          oeProjects: oes.map((oe) => oe.name),
          contacts: oes
            .flatMap((x) => x.oeStatuses)
            .filter((oe) => oe?.year === year)
            .map((x) => x?.contact ?? "")
            .filter(Boolean),
          clusters: oeClusters.map((cluster) => cluster.name),
        },
      };
    });

    headline.on("filter:apply", async ({ payload }) => {
      const state = app.getAppState();

      const oeOverviewPage = await fetchOEOverviewPage(state.year, payload);
      if (!state || !oeOverviewPage) {
        return;
      }

      app.setAppState({
        ...state,
        oeOverviewPage: oeOverviewPage,
        filterState: payload,
      });
    });

    headline.on("filter:clear", async () => {
      const state = app.getAppState();
      const oeOverviewPage = await fetchOEOverviewPage(state.year);
      if (!state || !oeOverviewPage) {
        return;
      }
      app.setAppState({
        ...state,
        oeOverviewPage: oeOverviewPage,
        filterState: {
          searchQueryFilter: "",
          contactFilter: [],
          nameFilter: [],
          topRiskFilter: [],
          reportStatusFilter: [],
          clusterFilter: [],
        },
      });
    });

    const numberOfOEsCard = view.addComponent({
      component: NumberCard,
      layoutProps: {
        xs: 12,
        sm: 6,
        md: 2.4,
      },
    });

    numberOfOEsCard.mapState(({ oeOverviewPage }) => ({
      title: "# of OEs",
      number: oeOverviewPage?.oesWithOEStatusForTheYear.length ?? 0,
    }));

    const submittedOEsCard = view.addComponent({
      component: NumberCard,
      layoutProps: {
        xs: 12,
        sm: 6,
        md: 2.4,
      },
    });

    submittedOEsCard.mapState((state) => ({
      title: "Submitted to Group",
      number: state.oeOverviewPage?.numberOfOEsSubmitted ?? "n/a",
    }));

    const oesWithCyberRiskAsTopRiskCard = view.addComponent({
      component: NumberCard,
      layoutProps: {
        xs: 12,
        sm: 6,
        md: 2.4,
      },
    });

    oesWithCyberRiskAsTopRiskCard.mapState((state) => ({
      title: "# of OEs with Cyber Risk as a Top Risk",
      number: state.oeOverviewPage?.numberOfOEsWithCyberRiskAsTopRisk ?? "n/a",
    }));

    const completedMandatoryScenariosCard = view.addComponent({
      component: NumberCard,
      layoutProps: {
        xs: 12,
        sm: 6,
        md: 2.4,
      },
    });

    completedMandatoryScenariosCard.mapState((state) => ({
      title: "Completed mandatory Scenarios",
      number: `${state.oeOverviewPage?.numberOfMandatoryScenariosCompleted ?? "n/a"}/${
        state.oeOverviewPage?.numberOfMandatoryScenarios ?? "n/a"
      }`,
    }));

    const completedSpecificScenarios = view.addComponent({
      component: NumberCard,
      layoutProps: {
        xs: 12,
        sm: 6,
        md: 2.4,
      },
    });

    completedSpecificScenarios.mapState((state) => ({
      title: "Completed OE specific Scenarios",
      number: `${state.oeOverviewPage?.numberOfOESpecificScenariosCompleted ?? "n/a"}/${
        state.oeOverviewPage?.numberOfOESpecificScenarios ?? "n/a"
      }`,
    }));

    const tableComponent = new Table(
      [
        { component: IndexCell },
        { component: TextCardCell },
        { component: TextCardCell },
        { component: TextCardCell },
        { component: ProgressBarCell },
        { component: ProgressBarCell },
        { component: DateCell },
        { component: CheckBoxCell },
      ],
      "overview"
    );

    const table = view.addComponent<
      DeriveTableProps<typeof tableComponent>,
      unknown,
      unknown
    >({
      component: tableComponent,
      layoutProps: {
        sm: 12,
      },
    });

    table.mapState(({ oeOverviewPage }) => {
      return {
        data:
          oeOverviewPage?.oesWithOEStatusForTheYear?.map(
            (
              {
                oe,
                oeStatus,
                numberOfScenarios,
                numberOfScenariosCompleted,
                numberOfSteps,
                numberOfStepsCompleted,
                reportingStatus,
                isTopRisk,
              },
              index
            ) => {
              const statuses = Object.values(reportingStatus ?? {}).map(
                (status) => status
              );

              function getUpdateStatus(
                allStatuses: ScenarioStepStatus[],
                oeStatus: FE_OEStatus
              ): UpdateStatus {
                if (oeStatus?.completionDate) {
                  return "up-to-date";
                }

                if (!allStatuses.length) {
                  return "outdated";
                }

                if (
                  allStatuses.includes("completed") ||
                  allStatuses.includes("started")
                ) {
                  return "in-progress";
                }

                return "outdated";
              }
              return {
                columnProps: [
                  {
                    indexValue: index + 1,
                  },
                  {
                    header: "OE",
                    body: oe.name,
                  },
                  {
                    header: "Contact Person",
                    body: oeStatus?.contact ?? "",
                  },
                  {
                    header: "Top Cyber Risk",
                    body: isTopRisk ? "Yes" : "No",
                  },
                  {
                    header: `Scenarios completed ${numberOfScenariosCompleted}/${numberOfScenarios}`,
                    progress: Math.round(
                      (numberOfScenariosCompleted / numberOfScenarios) * 100
                    ),
                    progressColor: "#94CADC",
                  },
                  {
                    header: `Scenario Steps completed  ${numberOfStepsCompleted}/${numberOfSteps}`,
                    progress: Math.round(
                      (numberOfStepsCompleted / numberOfSteps) * 100
                    ),
                    progressColor: "#94CADC",
                  },
                  {
                    header: "Completion Date",
                    date: oeStatus?.completionDate ?? "",
                  },
                  {
                    updateStatus: getUpdateStatus(statuses, oeStatus),
                  },
                ],
                rowProps: {
                  onClick: () =>
                    app.navigate(
                      generatePath(APP_ROUTES.oeReport, {
                        oeId: oe.id?.toString() ?? "",
                      })
                    ),
                },
              };
            }
          ) ?? [],
      };
    });

    const route = app.createRoute({
      path: APP_ROUTES.oeOverview,
      view,
    });

    route.on("load", async () => {
      const state = app.getAppState();
      const { year } = state;

      const oeOverviewPage = await fetchOEOverviewPage(year);

      app.setAppState({
        ...state,
        filterState: undefined,
        oeOverviewPage,
      });
    });
  }
}
