import {
  Box,
  Button,
  Dialog,
  DialogActions,
  Divider,
  Typography,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import {
  FE_Mitigation,
  FE_Scenario,
  OERatingResponse,
  OEReportPage,
} from "tracy-types";

import { theme } from "../../../theme";
import { MitigationActionsForm } from "../../scenarios/scenarios-steps/6-mitigation-actions";
import { ScenarioFlowStep } from "../../scenarios/types";

type PopupWithContentEvent = "popup:open" | "popup:close";

type MitigationDetailsPopupProps = {
  mitigation: FE_Mitigation | undefined;
  scenarios: FE_Scenario[] | undefined;
  open: boolean | undefined;
  cardTitle: string;
  oeReportPage: OEReportPage;
};

export const MitigationDetailsPopup: PimoReactComponent<
  MitigationDetailsPopupProps,
  PopupWithContentEvent,
  undefined
> = ({ mitigation, open = false, cardTitle, fireEvent, oeReportPage }) => {
  return (
    <Dialog
      onClose={() => fireEvent?.("popup:close")}
      open={open}
      maxWidth={"xl"}
      PaperProps={{ style: { borderRadius: 12 } }}
      slotProps={{
        backdrop: {
          sx: {
            height: "100%",
            backgroundColor: "rgba(0, 0, 0, 0)",
          },
        },
      }}
    >
      <Box
        sx={{
          display: "flex",
          p: 2,
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" width={1000}>
          {cardTitle}
        </Typography>
      </Box>
      <Divider
        sx={{
          bgcolor: theme.palette.secondary.main,
          height: "1px",
          borderWidth: 0,
        }}
      />
      <Box sx={{ p: 2 }}>
        <MitigationActionsForm
          mitigation={mitigation}
          disabled={true}
          type="update"
          scenario={{} as FE_Scenario}
          oeRatings={{} as OERatingResponse}
          step={{} as ScenarioFlowStep}
          oeReportPage={oeReportPage}
        />
      </Box>
      <DialogActions>
        <Button onClick={() => fireEvent?.("popup:close")} variant="contained">
          Close
        </Button>
      </DialogActions>
    </Dialog>
  );
};
