// THIS COMPONENT HAS BEEN COPIED FROM PIMO-COMPONENTS W/O ADAPTATIONS.
// FOR SOME REASON THE IMPORT VIA PIMO-COMPONENTS DOESN'T WORK AS EXPECTED.
// THIS SHOULD BE INVESTAGTED IN A FOLLOW UP.

import {
  Box,
  Card,
  CardContent,
  CardProps,
  Divider,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { PimoReactComponent } from "@pimo/pimo-app-builder";
import { ApexOptions } from "apexcharts";
import ReactApexChart from "react-apexcharts";

export interface DonutChartCardProps {
  /** Global Title of the Card, displayed to the left */
  title: string;
  /** Value of series */
  series: number[];
  /** Chart Title of the Graphic, displayed to the bottom */
  chartTitle?: string;
  /** Chart's colors */
  backgroundColor: string[];
  legendIcons?: string[];
  /** Chart's colors */
  labels: readonly string[];
  /**
   * colors schemes for the label badges, if this property is passed
   * the legend on the side will render badges instead of simple text
   */
  labelBadgesColors?: { chipColor: string; textColor: string }[];
  /** Chart's tooltips Labels */
  toolTipLabels?: readonly string[];
  toolTipEnabled?: boolean;
  /** show labels inside chart */
  showLabels?: boolean;
  /** enable hover effects over chart */
  enableHover?: boolean;
  /** callback function triggered on a donut section click */
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  onSectionClickHandler?: (event: any, chartContext: any, config: any) => void;
  /** (optional) more styling props */
  cardProps?: CardProps;
}

export const DonutChartCard: PimoReactComponent<DonutChartCardProps> = ({
  title,
  series,
  backgroundColor,
  labels,
  toolTipLabels = [],
  showLabels,
  toolTipEnabled,
  enableHover,
  cardProps = {},
  labelBadgesColors,
  legendIcons,
  onSectionClickHandler,
}) => {
  const options: ApexOptions = {
    labels: toolTipLabels as unknown as string[],
    colors: backgroundColor,
    legend: { show: false },
    dataLabels: { enabled: false },
    tooltip: {
      enabled: toolTipEnabled,
      fillSeriesColor: false,
      theme: "light",
    },
    plotOptions: {
      pie: {
        donut: {
          size: "70%",
          labels: {
            show: showLabels,
            name: {
              offsetY: 10,
            },
            total: {
              show: true,
              showAlways: true,
            },
            value: {
              show: false,
            },
          },
        },
      },
    },
  };

  if (!enableHover) {
    options.states = {
      hover: {
        filter: {
          type: "none",
        },
      },
    };
  }

  if (onSectionClickHandler) {
    options.chart = {
      events: {
        dataPointSelection: onSectionClickHandler,
        dataPointMouseEnter: (event: MouseEvent) => {
          (event?.target as HTMLInputElement).style.cursor = "pointer";
        },
      },
    };
  }

  const theme = useTheme();
  return (
    <Card
      data-testid="DonutChart"
      sx={{
        borderRadius: "12px",
        boxShadow: "none",
        height: "100%",
        width: "100%",
        "@media print": {
          border: "1px solid rgba(0, 0, 0, 0.12)",
        },
        ...cardProps.sx,
      }}
    >
      <Box sx={{ display: "flex", width: "100%", flexDirection: "column" }}>
        <Box
          sx={{
            display: "flex",
            p: 2,
            alignItems: "center",
          }}
        >
          <Typography variant="h5">{title}</Typography>
        </Box>
        <Divider
          sx={{
            bgcolor: theme.palette.secondary.main,
            height: "1px",
            borderWidth: 0,
          }}
        />

        <CardContent
          sx={{
            display: "flex",
            flexDirection: { xs: "column", xl: "row" },
            gap: {
              xs: 2,
              xl: 0,
            },
            pt: 6,
            height: useMediaQuery(theme.breakpoints.down("xl")) ? "100%" : 350,
            alignItems: "center",
            // override required due to the way that card contents are rendered by mui
            pb: "48px !important",
            "@media print": {
              pt: 2,
            },
          }}
        >
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "30px",
              minWidth: {
                xs: "calc(100% - 80px)",
                xl: labelBadgesColors ? 150 : "200px",
              },
              "@media print": {
                margin: "unset",
              },
            }}
          >
            {labels.map((label, index) =>
              labelBadgesColors ? (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15px",
                  }}
                  key={index}
                >
                  <Box
                    sx={{
                      lineHeight: "1.5rem",
                      fontSize: "12px",
                      height: "1.5rem",
                      textAlign: "center",
                      fontWeight: 500,
                      flex: "1 1 100%",
                      borderRadius: 12,
                      maxWidth: 90,
                      backgroundColor: labelBadgesColors[index].chipColor,
                      color: labelBadgesColors[index].textColor,
                    }}
                  >
                    {label}
                  </Box>
                  <Typography variant={"subtitle2"}>{series[index]}</Typography>
                </Box>
              ) : (
                <Box
                  sx={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: "15px",
                    maxWidth: 180,
                  }}
                  key={index}
                >
                  {legendIcons?.[index] ? (
                    <Box
                      component={"img"}
                      src={legendIcons[index]}
                      sx={{ marginLeft: -0.5 }}
                    />
                  ) : (
                    <Box
                      fontSize="small"
                      sx={{
                        borderRadius: "100%",
                        height: "15px",
                        width: "15px",
                        flex: "0 0 15px",

                        background: backgroundColor[index],
                        border:
                          backgroundColor[index].toUpperCase() === "#FFFFFF"
                            ? "#003781 1px solid"
                            : "",
                      }}
                    />
                  )}

                  <Typography
                    component="span"
                    sx={{ flex: "1 1 100%", maxWidth: 130 }}
                  >
                    {label}
                  </Typography>
                  <Typography variant={"subtitle2"}>{series[index]}</Typography>
                </Box>
              )
            )}
          </Box>

          <Box
            sx={{
              flex: "1 1 100%",
              display: "flex",

              justifyContent: "center",
              alignItems: "center",
              "@media print": {
                display: "none",
              },
            }}
          >
            <ReactApexChart
              options={options}
              series={series}
              type="donut"
              className="apex-charts"
            />
          </Box>
        </CardContent>
      </Box>
    </Card>
  );
};

export default DonutChartCard;
