import { STRAPI_URL } from "../../env";

export function createOe(oeName: string) {
  return fetch(`${STRAPI_URL}/api/bff-create-oe`, {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
    },
    credentials: "include",
    body: JSON.stringify({ name: oeName }),
  });
}
