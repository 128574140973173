import type { FE_OrganizationalEntity } from "tracy-types";

import { STRAPI_URL } from "../../env";

export async function fetchOEs(): Promise<FE_OrganizationalEntity[]> {
  try {
    const response = await fetch(`${STRAPI_URL}/api/bff/oes`, {
      credentials: "include",
    });

    if (response.status >= 400) {
      throw new Error(`Fetch failed with status ${response.status}.`);
    }

    const json = (await response.json()) as FE_OrganizationalEntity[];

    return json.sort((a, b) => a.name.localeCompare(b.name));
  } catch {
    return [];
  }
}
