import {
  Add,
  AssignmentOutlined,
  FileDownloadOutlined,
  InsertChartOutlinedTwoTone,
  Logout,
  Speed,
} from "@mui/icons-material";
import { App, EventEmitter, Plugin } from "@pimo/pimo-app-builder";
import { HeaderProps } from "@pimo/pimo-components";
import { generatePath } from "react-router-dom";

import { TracyAppState } from "../app";
import { TracyOverlay } from "../components/tracy-overlay";
import { APP_ROUTES } from "../constants";
import { STRAPI_URL } from "../env";
import { createOe } from "../helpers/fetch/create-oe";

export class TracyOverlayPlugin
  extends EventEmitter<"logout">
  implements Plugin<TracyAppState>
{
  onRegister(app: App<TracyAppState>): void {
    const overlayView = app.createOverlayView({ name: "Overlay" });

    const overlay = overlayView.addComponent({
      component: TracyOverlay,
    });

    overlay.mapState(({ isSidebarOpen, userProfile, year, oes }) => ({
      year,
      menuEntries: [
        {
          title: "Dashboard",
          link: APP_ROUTES.groupDashboard,
          level: 0,
          icon: Speed,
        },
        {
          title: "Status Overview",
          link: APP_ROUTES.oeOverview,
          level: 0,
          icon: AssignmentOutlined,
        },
        {
          title: "OE Assessment",
          level: 0,
          icon: InsertChartOutlinedTwoTone,
          items: [
            ...oes.map((oe) => ({
              title: oe.name,
              active: window.location.pathname.includes(
                `/oe-reports/${oe.id ?? 0}`
              ),
              link: generatePath(APP_ROUTES.oeReport, {
                oeId: oe.id?.toString() ?? "",
              }),
              level: 1,
            })),
          ].sort((entry1, entry2) => entry1.title.localeCompare(entry2.title)),
        },
      ],
      header: {
        logo: { big: "allianz.svg", small: "allianz-small.svg" },
        username: `${userProfile?.name ?? "Name n/a"} (${userProfile?.email ?? "Email n/a"})`,
        entries: [
          userProfile?.isAdmin
            ? {
                text: "Add OE",
                onClick: async () => {
                  const oeName = prompt("Enter OE name");
                  if (!oeName) {
                    return;
                  }
                  await createOe(oeName);
                  window.location.reload();
                },
                icon: Add,
              }
            : undefined,
          {
            text: "Download Excel Report (Current)",
            icon: FileDownloadOutlined,
            onClick: () =>
              window.open(`${STRAPI_URL}/api/bff/export/${year}/current`),
          },
          {
            text: "Download Excel Report (Residual)",
            icon: FileDownloadOutlined,
            onClick: () =>
              window.open(`${STRAPI_URL}/api/bff/export/${year}/residual`),
          },
          {
            text: "Logout",
            onClick: () => {
              this.fireEvent("logout");
            },
            icon: Logout,
          },
        ].filter(Boolean) as HeaderProps["entries"],
      },
      isSidebarOpen: isSidebarOpen,
    }));
    overlay.on("year-selected", ({ payload }) => {
      if (payload?.year == null) {
        return;
      }

      app.setAppState({
        ...app.getAppState(),
        year: payload?.year,
      });
    });

    overlay.on("overlay:toggle-sidebar", () => {
      app.setAppState({
        ...app.getAppState(),
        isSidebarOpen: !app.getAppState().isSidebarOpen,
      });
    });
  }
}
