import { Add } from "@mui/icons-material";
import {
  App,
  DeriveTableProps,
  Plugin,
  Route,
  Table,
} from "@pimo/pimo-app-builder";
import {
  ChipCell,
  DateCell,
  GridLayout,
  GridLayoutProps,
  IdentifierBadge,
  KeyInformationCard,
  LargeTextCard,
  TextCardCell,
} from "@pimo/pimo-components";
import { generatePath } from "react-router-dom";
import {
  type CommonRatingValue,
  type ControlEnvRatingValue,
  type EntityValidationResult,
  type FE_Mitigation,
  type FE_OrganizationalEntity,
  type FE_Scenario,
  type FinancialRatingValue,
  type OERatingResponse,
  type OEReportPage,
  type ProbabilityRatingValue,
  type RatingField,
  type ReputationalRatingValue,
  type ScenarioStepStatus,
  TOTAL_STEPS_OF_NORMAL_ASSESSMENT,
  TOTAL_STEPS_OF_SHORTCUT_ASSESSMENT,
  type UpdateStatus,
  type WorstScenarioRating,
} from "tracy-types";
import {
  formatDate,
  getColorSchemeAndTextForControlEnvRatingBadge,
  getColorSchemeForRating,
  getMaxRating,
  hasValidOEStatus,
  isReadonlyForOEID,
  isTopRisk,
} from "tracy-utils";

import { TracyAppState } from "../../app";
import { EditEnvironmentEffectivenessDialog } from "../../components/oe-report/edit/edit-environment-effectiveness-dialog";
import { LockedFlowDialog } from "../../components/oe-report/edit/locked-flow-dialog";
import { EditSettingsDialog } from "../../components/oe-report/edit/settings/edit-settings-dialog";
import OEReportSectionHeader from "../../components/oe-report/oe-report-section-header";
import OEReportTitleCard from "../../components/oe-report/oe-report-title-card";
import { MitigationDetailsPopup } from "../../components/oe-report/popups/mitigations-popup";
import { ScenarioOverview } from "../../components/oe-report/scenario-overview";
import { CyberRiskAssessmentTableContainer } from "../../components/oe-report/scenario-overview-table-container";
import StatusOfReportingCard from "../../components/oe-report/status-of-reporting-card";
import SummaryTableRiskCard from "../../components/oe-report/summary-table-risk";
import TextCard from "../../components/oe-report/text-card";
import { ShortcutAssessmentDialog } from "../../components/shortcut-assessment-dialog/shortcut-assessment-dialog";
import { PercentageCompletedCell } from "../../components/table/percentage-completed-cell";
import { OEReportProcessDescription } from "../../config/process-description";
import { RATING_LABEL } from "../../config/rating-label";
import { APP_ROUTES, STATUS_COLORS } from "../../constants";
import { addScenario } from "../../helpers/add-scenario";
import { updateControlEnvEffectiveness } from "../../helpers/fetch/fetch-control-envs-effectiveness";
import { fetchOERatings } from "../../helpers/fetch/fetch-oe-ratings";
import { fetchOEReportPage } from "../../helpers/fetch/fetch-oe-report-page";
import { updateOEStatus } from "../../helpers/fetch/fetch-oe-status";
import { fetchScenariosFormValues } from "../../helpers/fetch/fetch-scenarios-form-values";
import { postShortcutAssessment } from "../../helpers/fetch/fetch-shortcut-assessment";
import { generateRiskTableEntries } from "../../helpers/generate-objects/generate-risk-table-entries";
import { getSubversionTitle } from "../../helpers/get-subversion-title";
import { getLatestSteps } from "../../helpers/local-storage/get-latest-steps";
import { LatestStepParams } from "../../helpers/local-storage/types";
import { generateShortSummary } from "../../helpers/xlsx/generate-short-summary";

export type OEReportPluginState = {
  currentOEReportPage?: OEReportPage;
  currentOERatings?: OERatingResponse;
};

export class OEReportPlugin
  implements Plugin<TracyAppState, OEReportPluginState, "oeId">
{
  route?: Route<"oeId", TracyAppState, GridLayoutProps>;

  onRegister(app: App<TracyAppState>): void {
    const view = app.createView({
      layout: new GridLayout(),
      name: "OE Report Page",
    });

    const title = view.addComponent({
      component: OEReportTitleCard,
      layoutProps: { xs: 12 },
    });

    title.mapState(({ year, currentOEReportPage, permissions }) => {
      const isLocked = currentOEReportPage?.oeStatus?.isLocked;
      const statuses = Object.values(
        currentOEReportPage?.reportingStatus ?? {}
      ).map((status) => status);

      function getUpdateStatus(
        allStatuses: ScenarioStepStatus[]
      ): UpdateStatus {
        if (!allStatuses.length) {
          return "outdated";
        }

        if (allStatuses.every((status) => status === "completed")) {
          return "up-to-date";
        }

        if (allStatuses.includes("started")) {
          return "in-progress";
        }

        return "outdated";
      }

      return {
        title: `TRACY Report - ${
          currentOEReportPage?.oe?.name ?? ""
        } (${year})`,
        updateStatus: getUpdateStatus(statuses),
        processDescription: OEReportProcessDescription,
        completionDate: formatDate(
          currentOEReportPage?.oeStatus?.completionDate ?? ""
        ),
        isSubmitReportButtonEnabled:
          (currentOEReportPage?.scenarios?.every(
            ({ progress }) => progress.total === "completed"
          ) ??
            false) &&
          Boolean(currentOEReportPage?.oeStatus?.expertJudgment) &&
          !isLocked &&
          !isReadonlyForOEID(permissions, currentOEReportPage?.oe?.id ?? 0),
        isLocked,
      };
    });

    title.on("submit-report", async () => {
      const state = app.getAppState();
      const oeId = state?.currentOEReportPage?.oe.id;
      if (oeId == null) {
        return;
      }
      await updateOEStatus({
        oeId,
        oeStatus: {
          ...state?.currentOEReportPage?.oeStatus,
          completionDate: formatDate(new Date()),
          isLocked: true,
        },
      });
      await this.fetchOEReportPage(app, oeId);
    });

    const generalSettingsSectionHeadline = view.addComponent({
      component: OEReportSectionHeader<"edit">,
      layoutProps: { xs: 12 },
    });

    generalSettingsSectionHeadline.mapState(
      ({ currentOEReportPage, permissions }) => {
        const isLocked = currentOEReportPage?.oeStatus?.isLocked;

        return {
          title: "1. General Parameters and Introduction",
          updateStatus:
            currentOEReportPage?.reportingStatus?.generalSettings ?? "open",
          buttons: [
            {
              title: "Edit",
              event: "edit",
              isLocked:
                isLocked ||
                isReadonlyForOEID(
                  permissions,
                  currentOEReportPage?.oe?.id ?? 0
                ),
              showTooltip: isLocked,
            },
          ],
        };
      }
    );

    generalSettingsSectionHeadline.on("edit", () => {
      app.navigate(
        generatePath(APP_ROUTES.oeReportEditSettings, {
          oeId: `${app.getAppState().currentOEReportPage?.oe?.id ?? ""}`,
        })
      );
    });

    const settingsCard = view.addComponent({
      component: KeyInformationCard,
      layoutProps: { xs: 12, sm: 4 },
    });

    settingsCard.mapState(({ currentOEReportPage }) => ({
      title: `Settings for ${currentOEReportPage?.oe?.name ?? ""}`,
      entries: [
        // {
        //   label: "Selected Rating Scheme",
        //   value: currentOEReportPage?.oeStatus?.ratingSchema ?? "",
        // },
        {
          label: "Operating Profit",
          value: currentOEReportPage?.oeStatus?.operatingProfit
            ? [
                parseInt(
                  currentOEReportPage?.oeStatus?.operatingProfit
                ).toLocaleString(),
                "EUR",
              ].join(" ")
            : "N/A",
        },
        {
          label: "Subversion for Technical Vulnerability Exploitation",
          value: getSubversionTitle(
            currentOEReportPage?.oeStatus
              ?.subversionForTechnicalVulnerabilityExploitation ?? ""
          ),
        },
        {
          label: "Contact Person",
          value: currentOEReportPage?.oeStatus?.contact ?? "",
        },
      ],
    }));

    const statusOfReportingCard = view.addComponent({
      component: StatusOfReportingCard,
      layoutProps: { xs: 12, sm: 4 },
    });

    statusOfReportingCard.mapState((state) => ({
      title: "Status of Assessment",
      entries: [
        {
          label: "General Settings",
          updateStatus:
            state.currentOEReportPage?.reportingStatus?.generalSettings ??
            "open",
          linkTo: "general-settings",
        },
        {
          label: "Control Environment Effect.",
          updateStatus:
            state.currentOEReportPage?.reportingStatus
              ?.controlEnvironmentEffectiveness ?? "open",
          linkTo: "control-env",
        },

        {
          label: "Cyber Risk Scenarios & Mitigations",
          updateStatus:
            state.currentOEReportPage?.reportingStatus
              ?.scenariosAndMitigations ?? "open",
          linkTo: "scenarios",
        },
        {
          label: "Result & Expert Judgment",
          updateStatus:
            state.currentOEReportPage?.reportingStatus?.result ?? "open",
          linkTo: "result",
        },
      ],
    }));

    const introductionCard = view.addComponent({
      component: LargeTextCard,
      layoutProps: { xs: 12, sm: 4 },
    });

    introductionCard.mapState(() => ({
      cardTitle: "Introduction",
      textContent: `The **key goal** of **Top Risk Assessment Cyber** (TRACY) is to properly assess the cyber risk of an OE and to identify whether it is considered to be top risk. TRACY is done by using a simplified 10-step approach based on the Allianz Standard for Top Risk Assessment (ASTRA). The key parameters for the assessment of the OE’s cyber risk are the probability rating, financial impact and reputational impact. The final judgement if cyber shall be considered a top risk for TRACY (and top risk assessment in general) is made by an expert.

On the [Connect page](https://allianzms.sharepoint.com/sites/DE1890-connect-azse-group-technology/SitePages/TRACY.aspx) you will find **more information and detailed guidance** on TRACY, including the TRACY guideline, the TRACY cyber risk scenarios as well as a mapping of Allianz IT & IS Controls to the scenarios.

      `,
      // explitily set maxHeight ridiculously high so text does not have to be scrolled
      maxHeight: 10_000,
      cardProps: {
        sx: {
          background: "#003781",
          color: "#ffffff",
          a: { color: "#fff" },
        },
      },
    }));

    const controlEnvironmentEffectivenessSectionHeadline = view.addComponent({
      component: OEReportSectionHeader<"edit">,
      layoutProps: { xs: 12 },
    });

    controlEnvironmentEffectivenessSectionHeadline.mapState(
      ({ currentOEReportPage, permissions }) => {
        let overallRating = 0;
        if (currentOEReportPage?.controlEnvironmentEffectivenessEntries) {
          overallRating =
            getMaxRating(
              currentOEReportPage?.controlEnvironmentEffectivenessEntries.map(
                (entry) => entry.rating as RatingField
              )
            ) ?? 0;
        }

        const isLocked = currentOEReportPage?.oeStatus?.isLocked;

        return {
          title: "2. Control Environment Effectiveness",
          componentId: "control-env",
          chip: {
            title:
              overallRating !== 0
                ? `Rating: ${
                    RATING_LABEL.ControlEnv[overallRating as CommonRatingValue]
                  } = ${overallRating}`
                : "Rating: N/A",
            colorScheme: getColorSchemeForRating(
              overallRating as CommonRatingValue
            ).colorScheme,
          },
          updateStatus:
            currentOEReportPage?.reportingStatus
              ?.controlEnvironmentEffectiveness ?? "open",
          buttons: [
            {
              title: "Edit",
              event: "edit",
              isLocked:
                isLocked ||
                isReadonlyForOEID(
                  permissions,
                  currentOEReportPage?.oe?.id ?? 0
                ),
              showTooltip: isLocked,
            },
          ],
        };
      }
    );

    controlEnvironmentEffectivenessSectionHeadline.on("edit", () => {
      app.navigate(
        generatePath(APP_ROUTES.oeReportEditEncEffectiveness, {
          oeId: `${app.getAppState().currentOEReportPage?.oe?.id ?? ""}`,
        })
      );
    });

    const controlEnvironmentEffectivenessTable = new Table(
      [
        { component: IdentifierBadge },
        { component: TextCardCell },
        { component: TextCardCell },
        { component: TextCardCell },
        { component: TextCardCell },
      ],
      "report"
    );

    const controlEnvironmentEffectivenessTableComponent = view.addComponent<
      DeriveTableProps<typeof controlEnvironmentEffectivenessTable>,
      unknown,
      unknown
    >({
      component: controlEnvironmentEffectivenessTable,
      layoutProps: {
        xs: 12,
      },
    });

    controlEnvironmentEffectivenessTableComponent.mapState(
      ({ currentOEReportPage }) => ({
        tableHeaderEntries: [
          "ID",
          "Dimension",
          "Rating",
          "Description for Rating",
          "Comment on Rating",
        ],
        data:
          currentOEReportPage?.controlEnvironmentEffectivenessEntries?.map(
            (entry, index) => ({
              columnProps: [
                {
                  children: index + 1,
                },
                {
                  header: "",
                  body: entry.subgroup,
                },
                {
                  header: "",
                  body: `${entry.rating ?? "not set"}`,
                },
                {
                  header: "",
                  body: RATING_LABEL.ControlEnv[entry.rating] ?? "not set",
                },
                {
                  header: "",
                  body: entry.comment ?? "not set",
                },
              ],
              rowProps: {},
            })
          ) ?? [],
      })
    );

    const cyberRiskScenariosMitigationsSectionHeadline = view.addComponent({
      component: OEReportSectionHeader<"edit" | "addScenario">,
      layoutProps: { xs: 12 },
    });

    cyberRiskScenariosMitigationsSectionHeadline.mapState(
      ({ currentOEReportPage, permissions }) => {
        const isLocked = currentOEReportPage?.oeStatus?.isLocked;
        const hasShortcutAssessment =
          currentOEReportPage?.oe?.hasShortcutAssessment;

        return {
          title: "3. Cyber Risk Scenarios and Mitigations",
          componentId: "scenarios",
          updateStatus:
            currentOEReportPage?.reportingStatus?.scenariosAndMitigations ??
            "open",
          buttons: [
            {
              title: "Scenario",
              icon: <Add />,
              event: "addScenario",
              isLocked:
                hasShortcutAssessment ||
                isLocked ||
                isReadonlyForOEID(
                  permissions,
                  currentOEReportPage?.oe?.id ?? 0
                ),
              showTooltip: isLocked,
            },
            {
              title: "Edit",
              event: "edit",
              isLocked:
                hasShortcutAssessment ||
                isLocked ||
                isReadonlyForOEID(
                  permissions,
                  currentOEReportPage?.oe?.id ?? 0
                ),
              showTooltip: isLocked,
            },
          ],
        };
      }
    );

    cyberRiskScenariosMitigationsSectionHeadline.on("edit", () => {
      const { currentOEReportPage } = app.getAppState();
      const oe = currentOEReportPage?.oe;
      const scenario = currentOEReportPage?.scenarios?.[0];
      const latestSteps = getLatestSteps();

      function getLatestStepForOE(): LatestStepParams {
        const latestStep = latestSteps?.[oe?.id ?? 1]?.latestStep;

        if (latestStep) {
          return { ...latestStep, oeID: String(oe?.id ?? 1) };
        }

        return {
          oeID: String(oe?.id ?? 1),
          scenarioID: String(scenario?.id ?? 1),
          step: "step-0",
        };
      }

      const latestStep = getLatestStepForOE();

      if (hasValidOEStatus(currentOEReportPage?.oeStatus)) {
        app.navigate(
          generatePath(`${APP_ROUTES.oeScenarios}/*`, {
            oeId: latestStep.oeID,
            scenarioId: latestStep.scenarioID,
            "*": latestStep.step,
          })
        );
      } else {
        app.navigate(
          generatePath(APP_ROUTES.oeReportFlowLocked, {
            oeId: latestStep.oeID,
          })
        );
      }
    });

    cyberRiskScenariosMitigationsSectionHeadline.on("addScenario", async () =>
      addScenario(
        app,
        () =>
          void this.fetchOEReportPage(
            app,
            app.getAppState().currentOEReportPage?.oe.id ?? 0
          )
      )
    );

    const cyberRiskScenariosTable = new Table(
      [
        { component: IdentifierBadge },
        { component: TextCardCell },
        { component: TextCardCell },
        { component: PercentageCompletedCell },
        { component: TextCardCell },
      ],
      "report"
    );

    const cyberRiskScenariosTableComponent = view.addComponent<
      DeriveTableProps<typeof cyberRiskScenariosTable>,
      unknown,
      unknown
    >({
      component: cyberRiskScenariosTable,
      layoutProps: {
        xs: 12,
      },
    });

    cyberRiskScenariosTableComponent.mapState(({ currentOEReportPage }) => {
      const hasShortcutAssessment =
        currentOEReportPage?.oe?.hasShortcutAssessment;
      const latestSteps = getLatestSteps();
      const latestStepsByOE =
        latestSteps?.[String(currentOEReportPage?.oe?.id ?? 1)];
      const numberOfSteps = hasShortcutAssessment
        ? TOTAL_STEPS_OF_SHORTCUT_ASSESSMENT
        : TOTAL_STEPS_OF_NORMAL_ASSESSMENT;

      return {
        tableHeaderEntries: [
          "ID",
          "Scenario",
          "Short Description",
          "Completed Steps",
          "Mitigation",
        ],
        data:
          currentOEReportPage?.scenarios?.map((scenario, index) => ({
            columnProps: [
              {
                children: index + 1,
              },
              {
                header: "",
                body: scenario.name,
              },
              {
                header: "",
                body: scenario.shortDescription?.content ?? "",
              },
              {
                label: `${scenario.completedSteps} / ${numberOfSteps}`,
                percentage: Math.round(
                  (scenario.completedSteps / numberOfSteps) * 100
                ),
                colors: {
                  remaining: "#F5F5F5",
                  completed: STATUS_COLORS[scenario.progress.total ?? "open"],
                },
              },
              {
                header: "",
                body: scenario?.mitigations?.length > 0 ? "Yes" : "No",
              },
            ],
            rowProps: {
              onClick: () => {
                if (hasShortcutAssessment) {
                  return app.navigate(
                    generatePath(APP_ROUTES.oeReportShortCutAssessment, {
                      oeId: String(currentOEReportPage?.oe?.id ?? 1),
                      scenarioId: String(scenario?.id ?? 1),
                    })
                  );
                }

                function getLatestStepForScenario(): LatestStepParams {
                  const latestStep =
                    latestStepsByOE?.scenarios?.[String(scenario?.id) ?? 1];

                  if (latestStep) {
                    return {
                      ...latestStep,
                      oeID: String(currentOEReportPage?.oe?.id ?? 1),
                      scenarioID: String(scenario?.id ?? 1),
                    };
                  }

                  return {
                    oeID: String(currentOEReportPage?.oe?.id ?? 1),
                    scenarioID: String(scenario?.id ?? 1),
                    step: "step-0",
                  };
                }

                const latestStep = getLatestStepForScenario();

                if (!hasValidOEStatus(currentOEReportPage?.oeStatus)) {
                  app.navigate(
                    generatePath(APP_ROUTES.oeReportFlowLocked, {
                      oeId: latestStep.oeID,
                    })
                  );
                  return;
                }

                app.navigate(
                  generatePath(`${APP_ROUTES.oeScenarios}/*`, {
                    oeId: latestStep.oeID,
                    scenarioId: latestStep.scenarioID,
                    "*": latestStep.step,
                  })
                );
              },
              style: {
                cursor: hasShortcutAssessment ? "inherit" : "pointer",
              },
            },
          })) ?? [],
      };
    });

    const mitigationsTable = new Table(
      [
        { component: IdentifierBadge },
        { component: TextCardCell },
        { component: TextCardCell },
        { component: TextCardCell },
        { component: DateCell },
        { component: TextCardCell },
      ],
      "report"
    );

    const mitigationsTableComponent = view.addComponent<
      DeriveTableProps<typeof mitigationsTable>,
      unknown,
      unknown
    >({
      component: mitigationsTable,
      layoutProps: {
        xs: 12,
      },
    });

    mitigationsTableComponent.mapState(({ currentOEReportPage }) => ({
      tableHeaderEntries: [
        "ID",
        "Mitigation",
        "Scenario Addressed",
        "Owner",
        "Completion Date",
        "Source",
      ],
      data:
        currentOEReportPage?.mitigations?.map((mitigation, index) => ({
          columnProps: [
            {
              children: index + 1,
            },
            {
              body: mitigation.projectTitle,
              header: "",
            },

            {
              header: "",
              body: (mitigation.scenarios ?? [])
                .map((scenario) => scenario.name)
                .join(", "),
            },
            {
              header: "",
              body: mitigation.projectOwner ?? "",
            },
            {
              header: "",
              date: mitigation.completionDate ?? "",
            },
            {
              header: "",
              body: mitigation.source ?? "",
            },
          ],
          rowProps: {
            onClick: () => onMitigationPopupOpen(mitigation),
            style: {
              cursor: "pointer",
            },
          },
        })) ?? [],
    }));

    const mitigationDetailsPopup = view.addComponent({
      component: MitigationDetailsPopup,
    });

    mitigationDetailsPopup.mapState((state) => {
      return {
        oeReportPage: state.currentOEReportPage ?? ({} as OEReportPage),
        open: state.currentOEReportPage?.mitigationDetailsPopupOpen,
        cardTitle: `Details Mitigation: ${
          state.currentOEReportPage?.mitigationDetailsPopupContent
            ?.projectTitle ?? ""
        }`,
        mitigation: state.currentOEReportPage?.mitigationDetailsPopupContent,
        scenarios: state.currentOEReportPage?.scenarios,
      };
    });

    const onMitigationPopupOpen = (payload: FE_Mitigation) => {
      const state = app?.getAppState();
      if (!payload || !state) {
        return;
      }
      if (!state.currentOEReportPage) {
        return;
      }
      app?.setAppState({
        ...state,
        currentOEReportPage: {
          ...state.currentOEReportPage,
          mitigationDetailsPopupContent: payload,
          mitigationDetailsPopupOpen: true,
        },
      });
    };

    mitigationDetailsPopup.on("popup:close", () => {
      const state = app?.getAppState();
      if (!state) {
        return;
      }

      if (!state.currentOEReportPage) {
        return;
      }
      app?.setAppState({
        ...state,
        currentOEReportPage: {
          ...state.currentOEReportPage,
          mitigationDetailsPopupOpen: false,
        },
      });
    });

    const resultSectionHeadline = view.addComponent({
      component: OEReportSectionHeader<"editResult" | "downloadResult">,
      layoutProps: { xs: 12 },
    });

    resultSectionHeadline.mapState(({ currentOEReportPage, permissions }) => {
      const isLocked = currentOEReportPage?.oeStatus?.isLocked;

      return {
        title: "4. Result & Expert Judgment",
        componentId: "result",
        updateStatus: currentOEReportPage?.reportingStatus?.result ?? "open",
        buttons: [
          {
            title: "TRACY short summary (ORGS)",
            event: "downloadResult",
            isLocked: !isLocked,
            showTooltip: false,
          },
          {
            title: "Edit",
            event: "editResult",
            isLocked:
              isLocked ||
              isReadonlyForOEID(permissions, currentOEReportPage?.oe?.id ?? 0),
            showTooltip: isLocked,
          },
        ],
      };
    });

    resultSectionHeadline.on("downloadResult", async () => {
      const { currentOERatings, currentOEReportPage, year } = app.getAppState();

      const controlEnvRating = getMaxRating(
        (currentOEReportPage?.controlEnvironmentEffectivenessEntries ?? []).map(
          (cee) => cee.rating
        )
      ) as ControlEnvRatingValue;
      const worstCurrentRatings =
        currentOERatings?.worst?.current ?? ([] as WorstScenarioRating[]);
      const worstResidualRatings =
        currentOERatings?.worst?.residual ?? ([] as WorstScenarioRating[]);

      const probabilityRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.probabilityRating)
          .filter(Boolean) as RatingField[]
      ) as ProbabilityRatingValue;
      const financialRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.financialRating)
          .filter(Boolean) as FinancialRatingValue[]
      ) as FinancialRatingValue;
      const reputationalRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.reputationalRating)
          .filter(Boolean) as ReputationalRatingValue[]
      ) as ReputationalRatingValue;
      const targetScore = getMaxRating(
        worstResidualRatings
          .map(({ rating }) => rating.overallRating)
          .filter(Boolean) as CommonRatingValue[]
      ) as CommonRatingValue;

      await generateShortSummary({
        controlEnvRating,
        financialRating,
        probabilityRating,
        reputationalRating,
        targetScore,
        name: currentOEReportPage?.oe?.name ?? "",
        representingSource: worstCurrentRatings.map(({ name }) => name),
        year,
      });
    });

    resultSectionHeadline.on("editResult", () => {
      app.navigate(
        generatePath(APP_ROUTES.oeReportScenarioOverview, {
          oeId: `${app.getAppState().currentOEReportPage?.oe?.id ?? ""}`,
        })
      );
    });

    const currentRiskAssesmentTable = new Table(
      [
        { component: TextCardCell },
        { component: ChipCell },
        { component: ChipCell },
        { component: ChipCell },
        { component: ChipCell },
        { component: ChipCell },
        { component: ChipCell },
      ],
      "report"
    );

    const currentRiskAssesment = view.addComponent<
      DeriveTableProps<typeof currentRiskAssesmentTable>,
      unknown,
      unknown
    >({
      component: currentRiskAssesmentTable,
      layoutProps: {
        xs: 12,
      },
    });

    currentRiskAssesment.mapState((state) => ({
      tableHeaderEntries: [
        "Scenario Name",
        "Fin. Impact Rating",
        "Rep. Impact Rating",
        "Impact Severity",
        "Prob. Rating",
        "Overall Rating",
        "Top Risk",
      ],
      container: CyberRiskAssessmentTableContainer,
      containerProps: { title: "Current Cyber Risk Assessment " },
      data: generateRiskTableEntries(state, "current") ?? [],
    }));

    const residualRiskAssesmentTable = new Table(
      [
        { component: TextCardCell },
        { component: ChipCell },
        { component: ChipCell },
        { component: ChipCell },
        { component: ChipCell },
        { component: ChipCell },
        { component: ChipCell },
        { component: ChipCell },
      ],
      "report"
    );

    const residualRiskAssesment = view.addComponent<
      DeriveTableProps<typeof residualRiskAssesmentTable>,
      unknown,
      unknown
    >({
      component: residualRiskAssesmentTable,
      layoutProps: {
        xs: 12,
      },
    });

    residualRiskAssesment.mapState((state) => {
      return {
        tableHeaderEntries: [
          "Scenario Name",
          "Fin. Impact Rating",
          "Rep. Impact Rating",
          "Impact Severity",
          "Prob. Rating",
          "Overall Rating",
          "Top Risk",
          "Difference Overall Rating to Current Risk",
        ],
        container: CyberRiskAssessmentTableContainer,
        containerProps: { title: "Residual Cyber Risk Assessment" },
        data: generateRiskTableEntries(state, "residual") ?? [],
      };
    });

    const currentRiskSummary = view.addComponent({
      component: SummaryTableRiskCard,
      layoutProps: { xs: 6 },
    });

    currentRiskSummary.mapState((state) => {
      const worstCurrentRatings =
        state?.currentOERatings?.worst?.current ??
        ([] as WorstScenarioRating[]);

      const worstOverallRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.overallRating)
          .filter(Boolean) as RatingField[]
      );
      const worstFinancialRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.financialRating)
          .filter(Boolean) as RatingField[]
      );
      const worstReputationalRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.reputationalRating)
          .filter(Boolean) as RatingField[]
      );
      const worstProbabilityRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.probabilityRating)
          .filter(Boolean) as RatingField[]
      );

      const isTopRisk = worstCurrentRatings?.every(
        ({ rating }) => rating?.isTopRisk == null
      )
        ? "N/A"
        : worstCurrentRatings?.some(({ rating }) => rating?.isTopRisk)
          ? "Yes"
          : "No";

      const controlEnvRating = getMaxRating(
        (
          state?.currentOEReportPage?.controlEnvironmentEffectivenessEntries ??
          []
        ).map((cee) => cee.rating)
      ) as ControlEnvRatingValue;

      return {
        title: "Summary Table Current Cyber Risk",
        entries: [
          {
            label: "Top Risk",
            text: isTopRisk,
            colorScheme: {
              chipColor: isTopRisk === "N/A" ? "#C4C4C4" : "#2196F3",
              textColor: "white",
            },
          },
          {
            label: "Driving Source(s)",
            text: state.currentOERatings?.ratings?.length
              ? state.currentOERatings?.ratings?.filter(
                  ({ current }) => current.isTopRisk
                )?.length
                ? state.currentOERatings?.ratings
                    ?.filter(({ current }) => current.isTopRisk)
                    .map((scenario) => scenario.name)
                    .join(", ")
                : "No"
              : "N/A",
          },
          {
            label: "Representative Source",
            text: worstCurrentRatings?.length
              ? worstCurrentRatings?.map(({ name }) => name)?.join(", ")
              : "No Sources identified",
          },
          {
            label: "Maximum of Overall Rating",
            text: worstOverallRating
              ? `${worstOverallRating} (${RATING_LABEL.FinancialImpact[worstOverallRating]})`
              : "N/A",
            colorScheme:
              getColorSchemeForRating(worstOverallRating).colorScheme,
          },
          {
            label: "Overall Financial Impact Rating",
            text: worstFinancialRating
              ? `${worstFinancialRating} (${RATING_LABEL.FinancialImpact[worstFinancialRating]})`
              : "N/A",
            colorScheme:
              getColorSchemeForRating(worstFinancialRating).colorScheme,
          },
          {
            label: "Overall Reputational Impact Rating",
            text: worstReputationalRating
              ? `${worstReputationalRating} (${RATING_LABEL.ReputationalImpact[worstReputationalRating]})`
              : "N/A",
            colorScheme: getColorSchemeForRating(worstReputationalRating)
              .colorScheme,
          },
          {
            label: "Overall Probability Rating",
            text: worstProbabilityRating
              ? `${worstProbabilityRating} (${RATING_LABEL.ProbabilityRating[worstProbabilityRating]})`
              : "N/A",
            colorScheme: getColorSchemeForRating(worstProbabilityRating)
              .colorScheme,
          },
          {
            label: "Control Environment Effectiveness Rating",
            text: controlEnvRating
              ? `${controlEnvRating} (${RATING_LABEL.ControlEnv[controlEnvRating]})`
              : "N/A",
            colorScheme:
              getColorSchemeAndTextForControlEnvRatingBadge(controlEnvRating)
                .colorScheme,
          },
        ],
      };
    });

    const residualRiskSummary = view.addComponent({
      component: SummaryTableRiskCard,
      layoutProps: { xs: 6 },
    });

    residualRiskSummary.mapState((state) => {
      const worstResidualRatings =
        state?.currentOERatings?.worst?.residual ??
        ([] as WorstScenarioRating[]);
      const worstOverallRating = getMaxRating(
        worstResidualRatings
          .map(({ rating }) => rating.overallRating)
          .filter(Boolean) as RatingField[]
      );
      const worstFinancialRating = getMaxRating(
        worstResidualRatings
          .map(({ rating }) => rating.financialRating)
          .filter(Boolean) as RatingField[]
      );
      const worstReputationalRating = getMaxRating(
        worstResidualRatings
          .map(({ rating }) => rating.reputationalRating)
          .filter(Boolean) as RatingField[]
      );
      const worstProbabilityRating = getMaxRating(
        worstResidualRatings
          .map(({ rating }) => rating.probabilityRating)
          .filter(Boolean) as RatingField[]
      );
      const isTopRisk = worstResidualRatings?.every(
        ({ rating }) => rating?.isTopRisk == null
      )
        ? "N/A"
        : worstResidualRatings?.some(({ rating }) => rating?.isTopRisk)
          ? "Yes"
          : "No";

      const controlEnvRating = getMaxRating(
        (
          state?.currentOEReportPage?.controlEnvironmentEffectivenessEntries ??
          []
        ).map((cee) => cee.rating)
      ) as ControlEnvRatingValue;

      return {
        title: "Summary Table Residual Cyber Risk",
        entries: [
          {
            label: "Top Risk",
            text: isTopRisk,
            colorScheme: {
              chipColor: isTopRisk === "N/A" ? "#C4C4C4" : "#2196F3",
              textColor: "white",
            },
          },
          {
            label: "Driving Source(s)",
            text: state.currentOERatings?.ratings?.length
              ? state.currentOERatings?.ratings?.filter(
                  ({ residual }) => residual.isTopRisk
                )?.length
                ? state.currentOERatings?.ratings
                    ?.filter(({ residual }) => residual.isTopRisk)
                    .map((scenario) => scenario.name)
                    .join(", ")
                : "No"
              : "N/A",
          },
          {
            label: "Representative Source",
            text: worstResidualRatings?.length
              ? worstResidualRatings?.map(({ name }) => name)?.join(", ")
              : "No Sources identified",
          },
          {
            label: "Maximum of Overall Rating",
            text: worstOverallRating
              ? `${worstOverallRating} (${RATING_LABEL.FinancialImpact[worstOverallRating]})`
              : "N/A",
            colorScheme:
              getColorSchemeForRating(worstOverallRating).colorScheme,
          },
          {
            label: "Overall Financial Impact Rating",
            text: worstFinancialRating
              ? `${worstFinancialRating} (${RATING_LABEL.FinancialImpact[worstFinancialRating]})`
              : "N/A",
            colorScheme:
              getColorSchemeForRating(worstFinancialRating).colorScheme,
          },
          {
            label: "Overall Reputational Impact Rating",
            text: worstReputationalRating
              ? `${worstReputationalRating} (${RATING_LABEL.ReputationalImpact[worstReputationalRating]})`
              : "N/A",
            colorScheme: getColorSchemeForRating(worstReputationalRating)
              .colorScheme,
          },
          {
            label: "Overall Probability Rating",
            text: worstProbabilityRating
              ? `${worstProbabilityRating} (${RATING_LABEL.ProbabilityRating[worstProbabilityRating]})`
              : "N/A",
            colorScheme: getColorSchemeForRating(worstProbabilityRating)
              .colorScheme,
          },
          {
            label: "Control Environment Effectiveness Rating",
            text: controlEnvRating
              ? `${controlEnvRating} (${RATING_LABEL.ControlEnv[controlEnvRating]})`
              : "N/A",
            colorScheme:
              getColorSchemeAndTextForControlEnvRatingBadge(controlEnvRating)
                .colorScheme,
          },
        ],
      };
    });

    const topRisksCard = view.addComponent({
      component: TextCard<"edit">,
      layoutProps: { xs: 12 },
    });

    topRisksCard.on("edit", () => {
      app.navigate(
        generatePath(APP_ROUTES.oeReportScenarioOverview, {
          oeId: `${app.getAppState().currentOEReportPage?.oe?.id ?? ""}`,
        })
      );
    });

    topRisksCard.mapState(({ currentOEReportPage, permissions }) => {
      const isLocked = currentOEReportPage?.oeStatus?.isLocked;

      return {
        cardOneTitle:
          "Do you want to overrule Top Risk Result (for Current Assessment)?",
        textOneContent:
          currentOEReportPage?.oeStatus?.overruledTopRiskComment ?? "",
        cardTwoTitle: "Expert Judgement Comment (for entire Assessment)",
        cardTwoButtons: [
          {
            text: "Edit",
            event: "edit",
            isLocked:
              isLocked ||
              isReadonlyForOEID(permissions, currentOEReportPage?.oe?.id ?? 0),
          },
        ],
        textTwoContent: currentOEReportPage?.oeStatus?.expertJudgment ?? "",
        titleProps: { fontSize: "14px", fontWeight: 500 },
      };
    });

    const route = app.createRoute<"oeId">({
      path: APP_ROUTES.oeReport,
      view,
    });

    this.route = route;

    app.on("state:changed", () => {
      const oeId = app.getAppState().currentOEReportPage?.oe?.id;
      if (
        oeId != null &&
        app.getAppState().year !== app.getAppState().currentOEReportPageYear
      ) {
        void this.fetchOEReportPage(app, oeId);
      }
    });

    route.on("load", (event) => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
      if ((event as any).payload?.parameters?.scenarioId != null) {
        // we don't want to call this load function every time a new step is
        // taken in the scenarios plugin, which populates child routes of htis.
        // the way we can recognize it's a child route is by seeing if `scenarioId`
        // is set, which is required by the scenarios plugin, but not by this one.
        return;
      }
      const oeId = event.payload?.parameters?.oeId;
      if (oeId == null) {
        return;
      }

      void this.fetchOEReportPage(app, oeId);
    });

    this.buildSettingsDialog({ app, route });
    this.buildEnvEffectivenessDialog({ app, route });
    this.buildOverviewDialog({ app, route });
    this.buildLockedFlowDialog({ app, route });
    this.buildShortCutAssessmentDialog({ app, route });
  }

  private async fetchOEReportPage(
    app: App<TracyAppState>,
    oeId: string | number
  ): Promise<void> {
    const { year } = app.getAppState();

    const [pageData, oeRatings, newFormValues] = await Promise.all([
      fetchOEReportPage({
        id: +oeId,
        year,
      }),
      fetchOERatings({ id: +oeId, year }),
      fetchScenariosFormValues({
        id: +oeId,
        force: true,
        year,
      }),
    ]);

    if (!pageData) {
      return;
    }

    app.setAppState({
      ...app.getAppState(),
      currentOEReportPageYear: app.getAppState().year,
      currentScenariosFormValues: newFormValues,
      currentOEReportPage: pageData,
      currentOERatings: oeRatings,
    });
  }

  private buildSettingsDialog({
    app,
    route,
  }: {
    app: App<TracyAppState>;
    route: ReturnType<App<TracyAppState>["createRoute"]>;
  }) {
    const editView = app.createView({
      name: "Edit OE Settings",
    });

    const editDialog = editView.addComponent({ component: EditSettingsDialog });

    editDialog.mapState(({ currentOEReportPage, formValidationResult }) => ({
      oeName: currentOEReportPage?.oe?.name,
      year: app.getAppState().year,
      oeStatus: currentOEReportPage?.oeStatus,
      formValidationResult,
    }));

    editDialog.on("submit", async ({ payload }) => {
      app.setAppState({
        ...app.getAppState(),
        formValidationResult: undefined,
      });
      const oeId = app.getAppState().currentOEReportPage?.oe?.id;

      if (!payload || !oeId) {
        return;
      }

      try {
        await updateOEStatus({
          oeId,
          oeStatus: {
            id: payload.id,
            ratingSchema: payload.ratingSchema,
            operatingProfit: payload.operatingProfit,
            subversionForTechnicalVulnerabilityExploitation:
              payload.subversionForTechnicalVulnerabilityExploitation,
            contact: payload.contact,
          },
        });
        this.navigateBackToReport(app);
        await this.fetchOEReportPage(app, oeId);
      } catch (error) {
        app.setAppState({
          ...app.getAppState(),
          formValidationResult: error as EntityValidationResult,
        });
      }
    });

    editDialog.on("cancel", () => {
      app.setAppState({
        ...app.getAppState(),
        formValidationResult: undefined,
      });
      this.navigateBackToReport(app);
    });

    route.createChildRoute({
      path: APP_ROUTES.oeReportEditSettings,
      view: editView,
      isOverlayChild: true,
    });
  }

  private buildEnvEffectivenessDialog({
    app,
    route,
  }: {
    app: App<TracyAppState>;
    route: ReturnType<App<TracyAppState>["createRoute"]>;
  }) {
    const editView = app.createView({
      name: "Edit OE Settings",
    });

    const editDialog = editView.addComponent({
      component: EditEnvironmentEffectivenessDialog,
    });

    editDialog.mapState((state) => {
      const { currentOEReportPage, formValidationResult, year } = state;

      return {
        oeName: currentOEReportPage?.oe?.name,
        year,
        controlEnvironmentEffectivenessEntries:
          currentOEReportPage?.controlEnvironmentEffectivenessEntries ?? [],
        formValidationResult,
        updateStatus:
          currentOEReportPage?.reportingStatus
            ?.controlEnvironmentEffectiveness ?? "open",
      };
    });

    editDialog.on("submit", async ({ payload }) => {
      app.setAppState({
        ...app.getAppState(),
        formValidationResult: undefined,
      });
      const oeId = app.getAppState().currentOEReportPage?.oe?.id;

      if (!payload || !oeId) {
        return;
      }
      try {
        await updateControlEnvEffectiveness({ ...payload, oeId });

        this.navigateBackToReport(app);
        await this.fetchOEReportPage(app, oeId);
      } catch (error) {
        app.setAppState({
          ...app.getAppState(),
          formValidationResult: error as EntityValidationResult,
        });
      }
    });

    editDialog.on("cancel", () => {
      app.setAppState({
        ...app.getAppState(),
        formValidationResult: undefined,
      });
      this.navigateBackToReport(app);
    });

    route.createChildRoute({
      path: APP_ROUTES.oeReportEditEncEffectiveness,
      view: editView,
      isOverlayChild: true,
    });
  }

  private buildOverviewDialog({
    app,
    route,
  }: {
    app: App<TracyAppState>;
    route: ReturnType<App<TracyAppState>["createRoute"]>;
  }) {
    const scenarioOverview = app.createView({
      name: "Overview",
    });

    const scenarioOverviewDialogView = scenarioOverview.addComponent({
      component: ScenarioOverview,
    });
    scenarioOverviewDialogView.mapState((state) => {
      const { currentOERatings } = state;

      const worstCurrentRatings =
        currentOERatings?.worst?.current ?? ([] as WorstScenarioRating[]);
      const worstResidualRatings =
        currentOERatings?.worst?.residual ?? ([] as WorstScenarioRating[]);
      const worstCurrentOverallRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.overallRating)
          .filter(Boolean) as RatingField[]
      );
      const worstCurrentFinancialRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.financialRating)
          .filter(Boolean) as RatingField[]
      );
      const worstCurrentReputationalRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.reputationalRating)
          .filter(Boolean) as RatingField[]
      );
      const worstCurrentProbabilityRating = getMaxRating(
        worstCurrentRatings
          .map(({ rating }) => rating.probabilityRating)
          .filter(Boolean) as RatingField[]
      );
      const worstResidualOverallRating = getMaxRating(
        worstResidualRatings
          .map(({ rating }) => rating.overallRating)
          .filter(Boolean) as RatingField[]
      );
      const worstResidualFinancialRating = getMaxRating(
        worstResidualRatings
          .map(({ rating }) => rating.financialRating)
          .filter(Boolean) as RatingField[]
      );
      const worstResidualReputationalRating = getMaxRating(
        worstResidualRatings
          .map(({ rating }) => rating.reputationalRating)
          .filter(Boolean) as RatingField[]
      );
      const worstResidualProbabilityRating = getMaxRating(
        worstResidualRatings
          .map(({ rating }) => rating.probabilityRating)
          .filter(Boolean) as RatingField[]
      );
      const statuses = Object.values(
        state.currentOEReportPage?.reportingStatus ?? {}
      ).map((status) => status);

      function getUpdateStatus(
        allStatuses: ScenarioStepStatus[]
      ): UpdateStatus {
        if (!allStatuses.length) {
          return "outdated";
        }

        if (allStatuses.every((status) => status === "completed")) {
          return "up-to-date";
        }

        if (allStatuses.includes("started")) {
          return "in-progress";
        }

        return "outdated";
      }
      return {
        status: getUpdateStatus(statuses),
        oeStatus: state.currentOEReportPage?.oeStatus,
        formValidationResult: state.formValidationResult,
        currentCyberRiskAssessment:
          currentOERatings?.ratings.map(({ current, name }) => {
            const financialRating = current?.financialRating;
            const financialRatingColorScheme =
              getColorSchemeForRating(financialRating).colorScheme;

            const reputationalRating = current?.reputationalRating;
            const reputationalRatingColorScheme =
              getColorSchemeForRating(reputationalRating).colorScheme;

            const impactSeverity = current?.impactSeverityRating;
            const impactSeverityColorScheme =
              getColorSchemeForRating(impactSeverity).colorScheme;

            const probabilityRating = current?.probabilityRating;
            const probabilityRatingColorScheme =
              getColorSchemeForRating(probabilityRating).colorScheme;

            const overallRating = current?.overallRating;
            const overallRatingColorScheme =
              getColorSchemeForRating(overallRating).colorScheme;
            const topRisk = isTopRisk({
              impactSeverity,
              probabilityRating,
            });

            return {
              name,
              financialRating: {
                text: financialRating
                  ? `${financialRating} (${RATING_LABEL.FinancialImpact[financialRating]})`
                  : "N/A",
                ...financialRatingColorScheme,
              },
              impactRating: {
                text: reputationalRating
                  ? `${reputationalRating} (${RATING_LABEL.ReputationalImpact[reputationalRating]})`
                  : "N/A",
                ...reputationalRatingColorScheme,
              },
              impactSeverity: {
                text: impactSeverity
                  ? `${impactSeverity} (${RATING_LABEL.ReputationalImpact[impactSeverity]})`
                  : "N/A",
                ...impactSeverityColorScheme,
              },
              probabilityRating: {
                text: probabilityRating
                  ? `${probabilityRating} (${RATING_LABEL.ProbabilityRating[probabilityRating]})`
                  : "N/A",
                ...probabilityRatingColorScheme,
              },
              overallRating: {
                text: overallRating
                  ? `${overallRating} (${RATING_LABEL.FinancialImpact[overallRating]})`
                  : "N/A",
                ...overallRatingColorScheme,
              },
              topRisk: {
                text: topRisk == null ? "N/A" : topRisk ? "Yes" : "No",
                chipColor: topRisk == null ? "#C4C4C4" : "#2196F3",
                textColor: "white",
              },
            };
          }) ?? [],
        residualCyberRiskAssessment:
          currentOERatings?.ratings.map(({ delta, residual, name }) => {
            const financialRating = residual?.financialRating;
            const financialRatingColorScheme =
              getColorSchemeForRating(financialRating).colorScheme;

            const reputationalRating = residual.reputationalRating;
            const reputationalRatingColorScheme =
              getColorSchemeForRating(reputationalRating).colorScheme;

            const impactSeverity = residual?.impactSeverityRating;
            const impactSeverityColorScheme =
              getColorSchemeForRating(impactSeverity).colorScheme;

            const probabilityRating = residual?.probabilityRating;
            const probabilityRatingColorScheme =
              getColorSchemeForRating(probabilityRating).colorScheme;

            const overallRating = residual?.overallRating;
            const overallRatingColorScheme =
              getColorSchemeForRating(overallRating).colorScheme;
            const topRisk = isTopRisk({
              impactSeverity,
              probabilityRating,
            });

            return {
              name,
              financialRating: {
                text: financialRating
                  ? `${financialRating} (${RATING_LABEL.FinancialImpact[financialRating]})`
                  : "N/A",
                ...financialRatingColorScheme,
              },
              impactRating: {
                text: reputationalRating
                  ? `${reputationalRating} (${RATING_LABEL.ReputationalImpact[reputationalRating]})`
                  : "N/A",
                ...reputationalRatingColorScheme,
              },
              impactSeverity: {
                text: impactSeverity
                  ? `${impactSeverity} (${RATING_LABEL.ReputationalImpact[impactSeverity]})`
                  : "N/A",
                ...impactSeverityColorScheme,
              },
              probabilityRating: {
                text: probabilityRating
                  ? `${probabilityRating} (${RATING_LABEL.ProbabilityRating[probabilityRating]})`
                  : "N/A",
                ...probabilityRatingColorScheme,
              },
              overallRating: {
                text: overallRating ? `${overallRating}` : "N/A",
                ...overallRatingColorScheme,
              },
              topRisk: {
                text: topRisk == null ? "N/A" : topRisk ? "Yes" : "No",
                chipColor: topRisk == null ? "#C4C4C4" : "#2196F3",
                textColor: "white",
              },
              delta: {
                text: delta.overallRating ? `${delta.overallRating}` : "N/A",
                chipColor: "white",
                textColor: "black",
              },
            };
          }) ?? [],
        currentRiskSummaryTableEntries: (() => {
          const isTopRisk = worstCurrentRatings?.every(
            ({ rating }) => rating?.isTopRisk == null
          )
            ? "N/A"
            : worstCurrentRatings?.some(({ rating }) => rating?.isTopRisk)
              ? "Yes"
              : "No";

          return [
            {
              label: "Top Risk",
              text: isTopRisk,
              colorScheme: {
                chipColor: isTopRisk === "N/A" ? "#C4C4C4" : "#2196F3",
                textColor: "white",
              },
            },
            {
              label: "Driving Source(s)",
              text: state.currentOERatings?.ratings?.length
                ? state.currentOERatings?.ratings
                    ?.filter(({ current }) => current.isTopRisk)
                    .map((scenario) => scenario.name)
                    .join(", ")
                : "No",
            },
            {
              label: "Representative Source",
              text: worstCurrentRatings?.length
                ? worstCurrentRatings?.map(({ name }) => name)?.join(", ")
                : "No Sources identified",
            },
            {
              label: "Maximum of Overall Rating",
              text: worstCurrentOverallRating
                ? `${worstCurrentOverallRating} (${RATING_LABEL.FinancialImpact[worstCurrentOverallRating]})`
                : "N/A",
              colorScheme: getColorSchemeForRating(worstCurrentOverallRating)
                .colorScheme,
            },
            {
              label: "Overall Financial Impact Rating",
              text: worstCurrentFinancialRating
                ? `${worstCurrentFinancialRating} (${RATING_LABEL.FinancialImpact[worstCurrentFinancialRating]})`
                : "N/A",
              colorScheme: getColorSchemeForRating(worstCurrentFinancialRating)
                .colorScheme,
            },
            {
              label: "Overall Reputational Impact Rating",
              text: worstCurrentReputationalRating
                ? `${worstCurrentReputationalRating} (${RATING_LABEL.ReputationalImpact[worstCurrentReputationalRating]})`
                : "N/A",
              colorScheme: getColorSchemeForRating(
                worstCurrentReputationalRating
              ).colorScheme,
            },
            {
              label: "Overall Probability Rating",
              text: worstCurrentProbabilityRating
                ? `${worstCurrentProbabilityRating} (${RATING_LABEL.ProbabilityRating[worstCurrentProbabilityRating]})`
                : "N/A",
              colorScheme: getColorSchemeForRating(
                worstCurrentProbabilityRating
              ).colorScheme,
            },
          ];
        })(),
        residualRiskSummaryTableEntries: (() => {
          const isTopRisk = worstResidualRatings?.every(
            ({ rating }) => rating?.isTopRisk == null
          )
            ? "N/A"
            : worstResidualRatings?.some(({ rating }) => rating?.isTopRisk)
              ? "Yes"
              : "No";

          return [
            {
              label: "Top Risk",
              text: isTopRisk,
              colorScheme: {
                chipColor: isTopRisk === "N/A" ? "#C4C4C4" : "#2196F3",
                textColor: "white",
              },
            },
            {
              label: "Driving Source(s)",
              text: state.currentOERatings?.ratings?.length
                ? state.currentOERatings?.ratings
                    ?.filter(({ residual }) => residual.isTopRisk)
                    .map((scenario) => scenario.name)
                    .join(", ")
                : "No",
            },
            {
              label: "Representative Source",
              text: worstResidualRatings?.length
                ? worstResidualRatings?.map(({ name }) => name)?.join(", ")
                : "No Sources identified",
            },
            {
              label: "Maximum of Overall Rating",
              text: worstResidualOverallRating
                ? `${worstResidualOverallRating} (${RATING_LABEL.FinancialImpact[worstResidualOverallRating]})`
                : "N/A",
              colorScheme: getColorSchemeForRating(worstResidualOverallRating)
                .colorScheme,
            },
            {
              label: "Overall Financial Impact Rating",
              text: worstResidualFinancialRating
                ? `${worstResidualFinancialRating} (${RATING_LABEL.FinancialImpact[worstResidualFinancialRating]})`
                : "N/A",
              colorScheme: getColorSchemeForRating(worstResidualFinancialRating)
                .colorScheme,
            },
            {
              label: "Overall Reputational Impact Rating",
              text: worstResidualReputationalRating
                ? `${worstResidualReputationalRating} (${RATING_LABEL.ReputationalImpact[worstResidualReputationalRating]})`
                : "N/A",
              colorScheme: getColorSchemeForRating(
                worstResidualReputationalRating
              ).colorScheme,
            },
            {
              label: "Overall Probability Rating",
              text: worstResidualProbabilityRating
                ? `${worstResidualProbabilityRating} (${RATING_LABEL.ProbabilityRating[worstResidualProbabilityRating]})`
                : "N/A",
              colorScheme: getColorSchemeForRating(
                worstResidualProbabilityRating
              ).colorScheme,
            },
          ];
        })(),
      };
    });

    scenarioOverviewDialogView.on("submit", async ({ payload }) => {
      app.setAppState({
        ...app.getAppState(),
        formValidationResult: undefined,
      });

      const oeId = app.getAppState().currentOEReportPage?.oe?.id;
      if (!payload || !oeId) {
        return;
      }
      try {
        await updateOEStatus({
          oeId,
          oeStatus: {
            id: payload.id,
            overruledTopRiskComment: payload.overruledTopRiskComment,
            expertJudgment: payload.expertJudgment,
            overruledTopRisk: payload.overruledTopRisk,
          },
        });
        this.navigateBackToReport(app);
        await this.fetchOEReportPage(app, oeId);
      } catch (error) {
        app.setAppState({
          ...app.getAppState(),
          formValidationResult: error as EntityValidationResult,
        });
      }
    });

    scenarioOverviewDialogView.on("cancel", () => {
      app.setAppState({
        ...app.getAppState(),
        formValidationResult: undefined,
      });
      this.navigateBackToReport(app);
    });

    scenarioOverviewDialogView.on("go-back", () => {
      const { currentOEReportPage } = app.getAppState();

      if (!hasValidOEStatus(currentOEReportPage?.oeStatus)) {
        app.navigate(
          generatePath(APP_ROUTES.oeReportFlowLocked, {
            oeId: String(currentOEReportPage?.oe.id ?? 1),
          })
        );
        return;
      }

      app.navigate(
        generatePath(`${APP_ROUTES.oeScenarios}/*`, {
          oeId: String(currentOEReportPage?.oe.id ?? 1),
          scenarioId: String(currentOEReportPage?.scenarios[0].id ?? 1),
          "*": "step-0",
        })
      );
    });

    route.createChildRoute({
      path: APP_ROUTES.oeReportScenarioOverview,
      view: scenarioOverview,
      isOverlayChild: true,
    });
  }

  private buildLockedFlowDialog({
    app,
    route,
  }: {
    app: App<TracyAppState>;
    route: ReturnType<App<TracyAppState>["createRoute"]>;
  }) {
    const dialogView = app.createView({
      name: "Locked Flow",
    });

    const dialog = dialogView.addComponent({
      component: LockedFlowDialog,
    });

    dialog.on("close", () => {
      this.navigateBackToReport(app);
    });

    const childRoute = route.createChildRoute({
      path: APP_ROUTES.oeReportFlowLocked,
      view: dialogView,
      isOverlayChild: true,
    });

    childRoute.on("load", async (event) => {
      const oeId = event.payload?.parameters?.oeId;

      if (oeId == null) {
        return;
      }

      await this.fetchOEReportPage(app, oeId);

      const state = app.getAppState();

      if (hasValidOEStatus(state?.currentOEReportPage?.oeStatus)) {
        this.navigateBackToReport(app);
      }
    });
  }

  private buildShortCutAssessmentDialog({
    app,
    route,
  }: {
    app: App<TracyAppState>;
    route: ReturnType<App<TracyAppState>["createRoute"]>;
  }) {
    const dialogView = app.createView({
      name: "ShortCutAssessment",
    });

    const dialog = dialogView.addComponent({
      component: ShortcutAssessmentDialog,
    });

    dialog.mapState(({ currentOEReportPage, currentScenario }) => {
      const isLocked = currentOEReportPage?.oeStatus?.isLocked;

      return {
        isLocked,
        scenario: currentScenario,
      };
    });

    dialog.on("cancel", () => {
      this.navigateBackToReport(app);
    });

    dialog.on("submit", async ({ payload }) => {
      if (!payload) {
        return;
      }

      const { currentOEReportPage, currentScenario, year } = app.getAppState();

      await postShortcutAssessment({
        data: payload,
        oeId: currentOEReportPage?.oe?.id ?? 0,
        scenarioId: currentScenario?.id ?? 0,
        year,
      });
      await this.fetchOEReportPage(app, currentOEReportPage?.oe?.id ?? 0);
      this.navigateBackToReport(app);
    });

    const childRoute = route.createChildRoute({
      path: APP_ROUTES.oeReportShortCutAssessment,
      view: dialogView,
      isOverlayChild: true,
    });

    childRoute.on("load", async (event) => {
      const oeId = event.payload?.parameters?.oeId;
      const scenarioId = event.payload?.parameters?.scenarioId;

      if (oeId == null || scenarioId == null) {
        return;
      }

      await this.fetchOEReportPage(app, oeId);

      const state = app.getAppState();
      const {
        currentOEReportPage: {
          oe = {} as FE_OrganizationalEntity,
          scenarios = [] as FE_Scenario[],
        } = {},
        year,
      } = state;

      if (
        Object.hasOwn(oe, "hasShortcutAssessment") &&
        !oe?.hasShortcutAssessment
      ) {
        this.navigateBackToReport(app);
      } else {
        app.setAppState({
          ...state,
          currentScenario: scenarios?.find(
            ({ id }) => id === +scenarioId
          ) as FE_Scenario,
          year,
        });
      }
    });
  }

  private navigateBackToReport(app: App<TracyAppState>) {
    app.navigate(
      generatePath(APP_ROUTES.oeReport, {
        oeId: `${app.getAppState().currentOEReportPage?.oe?.id ?? ""}`,
      })
    );
  }
}
